import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';

import { Box, Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  ScheduleDialog,
  StepPanelHeader,
  TextButton,
} from 'src/components';
import hourglassIcon from 'src/assets/hourglass-icon.png';

import { userSelectors } from 'src/redux/user';
import { restartVideoInterview } from 'src/redux/videoInterview';

import { useUserVideoInterview } from 'src/hooks';
import { formatReminderDate } from 'src/utils';

export const IncompleteVILayout: React.FC = () => {
  const dispatch = useAppDispatch();

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const {
    allQuestionsAmount,
    finishedQuestionsAmount,
    userVideoInterview,
    isLoading,
  } = useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const userReminderDate = userData.videoInterviewReminderDate;

  const handleRestartVideoInterview = () => {
    if (userVideoInterview && !isLoading) {
      return dispatch(restartVideoInterview(userVideoInterview.id));
    }
  };

  const handleScheduleSuccess = () => {
    setShowScheduleDialog(false);
  };

  return (
    <>
      <StepPanelHeader
        srcSetPng={hourglassIcon}
        imageSx={{ width: '45px', height: '68px' }}
        alt="Star"
        data-testid="success-title"
      >
        {finishedQuestionsAmount} out of {allQuestionsAmount} questions answered
      </StepPanelHeader>
      <Typography variant="body1" mb={0}>
        Looks like you’ve stopped the video interview.
      </Typography>
      <Flex justifyContent="center" columnGap="45px">
        <CustomButton
          onClick={handleRestartVideoInterview}
          label="Resume recording"
          color="primary"
          isLoading={isLoading}
        />
        <Box
          sx={{
            borderRadius: '4px',
            padding: '9px 24px',
            background: '#FFF2DE',
          }}
        >
          <Typography variant="body2" color="text.secondary" noWrap>
            {userReminderDate
              ? formatReminderDate(userReminderDate, userData.country!)
              : 'Feel free to record it later'}
          </Typography>
          <TextButton
            underlined
            onClick={() => setShowScheduleDialog(true)}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {userReminderDate ? 'reschedule or cancel' : 'Add to your calendar'}
          </TextButton>
        </Box>
      </Flex>
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={() => setShowScheduleDialog(false)}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview
        />
      )}
      <Typography variant="body1" mb={0} maxWidth="275px">
        Our Talent Executive will review your application once it’s finished.
      </Typography>
    </>
  );
};
