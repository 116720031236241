import { useUserVideoInterview } from 'src/hooks';
import { StepPanel } from '../../../../components';
import { VIUnderReview } from './messages/VIUnderReview';
import { DefaultMessage } from './messages/DefaultMessage';

export const SuccessLayout = () => {
  const { userVideoInterview } = useUserVideoInterview();

  return (
    <StepPanel maxWidth="36rem">
      {userVideoInterview && !userVideoInterview.reviewer ? (
        <VIUnderReview />
      ) : (
        <DefaultMessage />
      )}
    </StepPanel>
  );
};
