/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';

import { Box, Link, MenuItem, TextField, Typography } from '@mui/material';
import { CustomButton, TextButton } from 'src/components';

import { referFriendSelectors, submitReferral } from 'src/redux/referFriend';
import { referralJobOpeningsSelectors } from 'src/redux/referralJobOpenings';

import { parseQueryParams } from 'src/utils';
import { validationSchema } from '../validationSchema';
import { ReferralJobOpeningModal } from './ReferralJobOpeningModal';
import { logEvent } from 'src/services';

const ReferFriendForm = ({
  onReferralSubmit,
}: {
  onReferralSubmit: () => void;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const excludedKeys = /[,|*+0-9]/;

  const isSubmitting = useAppSelector(
    referFriendSelectors.getReferralSubmitIsLoading,
  );
  const jobOpenings = useAppSelector(
    referralJobOpeningsSelectors.getReferralJobOpenings,
  );

  const [isAddNote, setIsAddNote] = useState(false);
  const [isOpenJobOpeningModal, setOpenJobOpeningModal] =
    useState<boolean>(false);

  const { utm_source, utm_medium, referred_position, utm_campaign } =
    parseQueryParams<{
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      referred_position?: string;
    }>(location.search);

  const handleCloseJobOpeningModal = useCallback(
    () => setOpenJobOpeningModal(false),
    [],
  );

  const handleMouseDownPosition = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenJobOpeningModal(true);
    },
    [setOpenJobOpeningModal],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      position: '',
      note: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      logEvent('launchpod - refer-fried-page_submit-click');

      const response = await dispatch(
        submitReferral({
          name: values.name,
          email: values.email,
          note: values.note,
          position: values.position,
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
        }),
      );
      if (submitReferral.fulfilled.match(response)) {
        onReferralSubmit();
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (referred_position && jobOpenings?.length) {
      const jobOpening = jobOpenings.find(
        (jobOpening) => jobOpening.Careers_Site_Link === referred_position,
      );
      formik.setFieldValue('position', jobOpening?.id ?? '');
    }
  }, [referred_position, jobOpenings]);

  const handleFriendNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value, name } = event.target;
    if (!excludedKeys.test(value)) {
      formik.setFieldValue(name, value);
    }
  };

  const handleSelectJobOpening = useCallback(
    (payload: string) => {
      formik.setFieldValue('position', payload);
      handleCloseJobOpeningModal();
    },
    [handleCloseJobOpeningModal],
  );

  return (
    <>
      <ReferralJobOpeningModal
        isOpen={isOpenJobOpeningModal}
        handleClose={handleCloseJobOpeningModal}
        handleSelectJobOpening={handleSelectJobOpening}
      />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ width: '100%' }}
      >
        <Box
          sx={(theme) => ({
            background: theme.palette.common.white,
            padding: '62px 100px',
            borderRadius: '48px',
            maxWidth: '603px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
              padding: '48px 32px',
            },
          })}
        >
          <Typography
            variant="h2"
            color="primary"
            sx={{ fontSize: '30px', fontWeight: 600 }}
          >
            Refer a friend
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              name="email"
              type="email"
              label="Your friend’s email"
              id="email"
              variant="outlined"
              autoComplete="email"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : "We'll send your friend a link to provide details and resume"
              }
            />
            <TextField
              name="name"
              type="text"
              label="Friend's full name"
              id="name"
              variant="outlined"
              fullWidth
              onChange={handleFriendNameChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              select
              name="position"
              label="Position"
              id="position"
              variant="outlined"
              fullWidth
              focused={false}
              onBlur={formik.handleBlur}
              value={formik.values.position}
              error={formik.touched.position && Boolean(formik.errors.position)}
              helperText={formik.touched.position && formik.errors.position}
              InputProps={{
                sx: {
                  cursor: 'pointer',
                },
              }}
              SelectProps={{
                onClick: handleMouseDownPosition,
                open: false,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {jobOpenings?.map((jobOpening) => (
                <MenuItem key={jobOpening.id} value={jobOpening.id}>
                  {jobOpening.Marketing_Title || jobOpening.Job_Opening_Name}
                </MenuItem>
              ))}
            </TextField>
            {isAddNote ? (
              <TextField
                name="note"
                label="Note"
                type="textarea"
                id="note"
                variant="outlined"
                value={formik.values.note}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiline
                maxRows={10}
                error={!!formik.values.note && Boolean(formik.errors.note)}
                helperText={formik.values.note && formik.errors.note}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '40px 16px 32px',
                  },
                  '& .MuiInputBase-input': {
                    padding: '0 !important',
                  },
                }}
              />
            ) : (
              <TextButton
                onClick={() => setIsAddNote(true)}
                sx={{ justifyContent: 'start' }}
              >
                Add a note
              </TextButton>
            )}
          </Box>

          <Box mt={4}>
            <CustomButton
              onClick={() => {
                formik.handleSubmit();
              }}
              label="Refer a friend"
              color="primary"
              fullWidth
              isLoading={isSubmitting}
              isDisabled={!formik.isValid}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mt={6}>
          <Typography
            variant="body2"
            color="secondary"
            textAlign="center"
            fontSize="0.675rem"
            mx={2}
          >
            <Link component={RouterLink} to="/terms-of-use">
              Terms of use
            </Link>
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            textAlign="center"
            fontSize="0.675rem"
            mx={2}
          >
            <Link href="https://agileengine.com/privacy-policy" target="_blank">
              Privacy policy
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ReferFriendForm;
