import { useMemo } from 'react';
import { useAppSelector } from 'src/redux';

import { Box, SvgIcon, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactComponent as DescriptionIcon } from 'src/assets/description-icon.svg';

import { userSelectors } from 'src/redux/user';
import { useUserTestTasks, useUserVideoInterview } from 'src/hooks';

export const ApplicationProgress: React.FC = () => {
  const user = useAppSelector(userSelectors.getUserData);

  const { availableTestTasks, passedTestTasks } = useUserTestTasks();
  const hasPassedTestTask = !!passedTestTasks?.length;

  const {
    availableVideoInterview,
    userVideoInterview,
    isUserCompletedVideoInterview,
  } = useUserVideoInterview();
  const hasAvailableVideoInterview = !!availableVideoInterview?.length;

  const steps = useMemo(
    () => [
      {
        label: 'Tell us about yourself',
        time: '2 minutes',
        description: null,
        isCompleted: !!user?.profileSubmittedAt,
        exists: true,
      },
      {
        label: 'Confirm requirements',
        time: '1 sec!',
        description: null,
        isCompleted: !!user?.crucialRequirementsSubmittedAt,
        exists: !!user?.crucialRequirements,
      },
      {
        label: 'Pass a short test',
        time: '30-60 min',
        description:
          'Coding challenge that cuts further interview time and reduces bias',
        isCompleted: hasPassedTestTask,
        exists: !!availableTestTasks.length,
      },
      {
        label: 'Record a short video',
        description:
          'Introduce yourself on a video, instead of waiting for an interview ',
        time: '5 min',
        isCompleted: isUserCompletedVideoInterview,
        exists: hasAvailableVideoInterview || !!userVideoInterview,
      },
      {
        label: 'Ace the interview with our manager',
        description:
          'Schedule a call yourself right away after your video is reviewed',
        time: null,
        isCompleted: false,
        exists: true,
      },
      {
        label: 'Get an offer',
        time: 'As quick as possible',
        isCompleted: false,
        exists: true,
      },
    ],
    [user],
  );

  return (
    <Box>
      <Typography
        variant="body2"
        color="text.secondary"
        mb={3}
        textAlign="left"
      >
        Your application progress
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        maxWidth="320px"
        width="100%"
      >
        {steps
          .filter((step) => step.exists)
          .map((step, idx, arr) => {
            const isCurrentStep =
              arr[idx - 1]?.isCompleted && !arr[idx]?.isCompleted;
            const isPending =
              [arr.length - 1, arr.length - 2].includes(idx) &&
              arr.at(-3)?.isCompleted;

            return (
              <Box key={step.label}>
                <Box display="flex" alignItems="center" gap={2}>
                  {isPending ? (
                    <AccessTimeIcon
                      sx={{
                        fontSize: '23px',
                        color: (theme) => theme.palette.background.stepsCircle,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        borderRadius: '0.5rem',
                        backgroundColor: step.isCompleted
                          ? 'background.success'
                          : isCurrentStep
                          ? 'background.brandSecondary'
                          : 'background.stepsCircle',
                        width: '23px',
                        height: '23px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {step.isCompleted ? (
                        <CheckIcon
                          sx={{
                            fontSize: '12px',
                            color: (theme) => theme.palette.common.white,
                          }}
                        />
                      ) : isCurrentStep ? (
                        <ArrowForwardIcon
                          sx={{
                            fontSize: '12px',
                            color: (theme) => theme.palette.common.white,
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          color="common.white"
                          sx={{
                            fontSize: '12px',
                            fontWeight: 700,
                          }}
                        >
                          {idx + 1}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          display: 'block',
                        },
                      })}
                    >
                      <Typography
                        variant="body1"
                        color="text.primary"
                        textAlign="left"
                      >
                        {step.label}
                        {!!step.time && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            textAlign="left"
                          >
                            {' '}
                            • {step.time}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {!!step.description && (isCurrentStep || isPending) && (
                  <Box display="flex" alignItems="center" mt={1} gap={2}>
                    <Box
                      sx={{
                        borderRadius: '0.5rem',
                        backgroundColor: 'rgba(187, 40, 190, 0.06)',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SvgIcon
                        inheritViewBox
                        component={DescriptionIcon}
                        sx={() => ({
                          width: '16px',
                          height: '16px',
                        })}
                      />
                    </Box>

                    <Typography
                      variant="body2"
                      color="text.purple"
                      textAlign="left"
                    >
                      {step.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
