import { createSlice } from '@reduxjs/toolkit';
import { userGeoLocationStoredKey } from './userGeoLocation.const';
import { API_STATUS } from '../../constants';
import { fetchUserGeoLocation } from './userGeoLocation.thunks';

export interface IUserGeoLocationSliceState {
  geoLocation: {
    country_name: string;
    country_code: string;
    ip: string;
  } | null;
  apiStatus: API_STATUS;
}

const initialState: IUserGeoLocationSliceState = {
  geoLocation: null,
  apiStatus: API_STATUS.IDLE,
};

export const userGeoLocationSlice = createSlice({
  name: userGeoLocationStoredKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGeoLocation.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(fetchUserGeoLocation.fulfilled, (state, action) => {
        state.apiStatus = API_STATUS.COMPLETE;
        state.geoLocation = action.payload;
      })
      .addCase(fetchUserGeoLocation.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
