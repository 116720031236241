import React from 'react';

import { TextButton } from '../../../components';
import { Box, Stack, styled, Typography } from '@mui/material';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';

import StartRecordingButton from './StartRecordingButton';
import LoadingRecordingButton from './LoadingRecordingButton';
import ProceedToTheNexQuestionButton from './ProceedToTheNexQuestionButton';
import FinishInterviewButton from './FinishInterviewButton';
import StopRecordingButton from './StopRecordingButton';

import { VideoInterviewQuestionStatus } from 'src/types';
import { logEvent } from 'src/services';

const StyledActionButtonTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  color: 'rgba(0, 0, 0, 0.38)',
});

const StyledTextButton = styled(TextButton)(() => ({
  fontWeight: 500,
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: '1rem',
  display: 'flex',
  gap: '0.5rem',
  whiteSpace: 'nowrap',
  span: {
    textDecoration: 'underline',
  },
}));

interface IProps {
  questionStatus: VideoInterviewQuestionStatus;
  setQuestionStatus: (questionStatus: VideoInterviewQuestionStatus) => void;
  setShowExitInterviewDialog: (showExitInterviewDialog: boolean) => void;
  showReportIssueDialog: () => void;
  handleShowRetakeDialog: () => void;
  handleFinishVideoInterview: () => void;
  isLastQuestion: boolean;
  uploadProgress: number;
  isEngineeringSpecialization: boolean;
}

const RecordActionsButtons: React.FC<IProps> = ({
  questionStatus,
  setQuestionStatus,
  setShowExitInterviewDialog,
  showReportIssueDialog,
  handleShowRetakeDialog,
  isLastQuestion,
  handleFinishVideoInterview,
  uploadProgress,
  isEngineeringSpecialization,
}) => {
  const handleExitInterview = () => {
    setShowExitInterviewDialog(true);
  };

  const handleStartInterview = () => {
    logEvent('launchpod - video-interview-record-page_start-record-clicked');

    setQuestionStatus(VideoInterviewQuestionStatus.LOADING);
  };

  const handleStopQuestion = () => {
    setQuestionStatus(VideoInterviewQuestionStatus.STOPPING);
  };

  const handleNextQuestion = () => {
    logEvent(
      'launchpod - video-interview-record-page_proceed-next-question-clicked',
    );

    setQuestionStatus(VideoInterviewQuestionStatus.FINISHED);
  };

  const finishInterviewOnClick = (e: React.SyntheticEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    logEvent(
      'launchpod - video-interview-record-page_finish-interview-clicked',
    );
    handleFinishVideoInterview();
  };

  const showRetakeButton =
    isEngineeringSpecialization &&
    (questionStatus === VideoInterviewQuestionStatus.STARTED ||
      questionStatus === VideoInterviewQuestionStatus.STOPPING ||
      questionStatus === VideoInterviewQuestionStatus.STOPPED);

  return (
    <>
      <Box
        sx={(theme) => ({
          marginTop: '50px',
          display: 'flex',
          gap: '2.5rem',
          alignItems: 'center',
          [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
            maxWidth: '511px',
            textAlign: 'center',
            marginTop: '20px',
          },
          ['@media (max-width: 1200px)']: {
            maxWidth: '100%',
          },
        })}
      >
        {questionStatus === VideoInterviewQuestionStatus.PREPARATION && (
          <StartRecordingButton handleStartInterview={handleStartInterview} />
        )}
        {questionStatus === VideoInterviewQuestionStatus.LOADING && (
          <LoadingRecordingButton />
        )}
        {(questionStatus === VideoInterviewQuestionStatus.STARTED ||
          questionStatus === VideoInterviewQuestionStatus.STOPPING) && (
          <StopRecordingButton
            handleStopQuestion={handleStopQuestion}
            questionStatus={questionStatus}
            uploadProgress={uploadProgress}
          />
        )}
        {questionStatus === VideoInterviewQuestionStatus.STOPPED &&
          !isLastQuestion && (
            <ProceedToTheNexQuestionButton
              handleNextQuestion={handleNextQuestion}
            />
          )}
        {questionStatus === VideoInterviewQuestionStatus.STOPPED &&
          isLastQuestion && (
            <FinishInterviewButton
              handleFinishInterview={finishInterviewOnClick}
            />
          )}
        <Stack
          gap="2.5rem"
          flexDirection="row"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              flexWrap: 'wrap',
              justifyContent: 'center',
            },
          })}
        >
          {showRetakeButton && (
            <Stack alignItems="flex-start" justifyContent="center">
              <StyledActionButtonTypography variant="body2">
                Want another take?
              </StyledActionButtonTypography>
              <StyledTextButton
                startIcon={
                  <SettingsBackupRestoreRoundedIcon
                    color="secondary"
                    fontSize="small"
                  />
                }
                variant="secondary"
                onClick={handleShowRetakeDialog}
                sx={{ fontSize: '14px', fontWeight: 200 }}
              >
                Retake
              </StyledTextButton>
            </Stack>
          )}
          <Stack alignItems="flex-start" justifyContent="center">
            <StyledActionButtonTypography variant="body2">
              Having trouble recording?
            </StyledActionButtonTypography>
            <StyledTextButton
              startIcon={
                <MapsUgcRoundedIcon color="secondary" fontSize="small" />
              }
              variant="secondary"
              onClick={showReportIssueDialog}
              sx={{ fontSize: '14px', fontWeight: 200 }}
            >
              Report issue
            </StyledTextButton>
          </Stack>
          <Stack alignItems="flex-start" justifyContent="center">
            <StyledActionButtonTypography variant="body2">
              Not comfortable?
            </StyledActionButtonTypography>
            <StyledTextButton
              startIcon={
                <DirectionsRunRoundedIcon color="secondary" fontSize="small" />
              }
              variant="secondary"
              onClick={handleExitInterview}
              sx={{ fontSize: '14px', fontWeight: 200 }}
            >
              Exit interview
            </StyledTextButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default RecordActionsButtons;
