import { meetingStoreKey } from './meeting.const';
import { IMeetingSliceState } from './meeting.slice';

interface IState {
  [meetingStoreKey]: IMeetingSliceState;
}

const selectMeetingState = (state: IState) => {
  return state[meetingStoreKey];
};

const getMeetingsAPIStatus = (state: IState) => {
  return selectMeetingState(state).apiStatus;
};

const getMeetingsData = (state: IState) => {
  return selectMeetingState(state).data;
};

const getIsPollingActiveMeeting = (state: IState) => {
  return selectMeetingState(state).isPollingActiveMeeting;
};

export const meetingSelectors = {
  getMeetingsAPIStatus,
  getMeetingsData,
  getIsPollingActiveMeeting,
};
