import { v4 as uuidV4 } from 'uuid';
import axios from 'axios';

import { LocalStorageService } from './localStorageService';

import { LOCAL_STORAGE_KEYS } from 'src/constants';

interface IEventLog {
  name: string;
  data: any;
  loggedAt: string;
}

interface IBrowserData {
  userAgent?: string | null;
  platform?: string | null;
  language: string | null;
  screenResolution?: string | null;
  timezone?: string | null;
  touchSupport?: boolean | null;
}

class LPAnalytics {
  static _instance: LPAnalytics | null = null;

  private URL = '/api/events';
  private API_KEY = 'api_key';
  private events: IEventLog[] = [];
  private sessionId: string | null = null;
  private userId: string | null = null;
  private browserData: IBrowserData | null = null;

  private constructor() {
    let sessionId = LocalStorageService.getState<string>(
      LOCAL_STORAGE_KEYS.ANALYTICS_ID,
    );

    if (!sessionId) {
      sessionId = uuidV4();
      LocalStorageService.setState(LOCAL_STORAGE_KEYS.ANALYTICS_ID, sessionId);
    }

    this.sessionId = sessionId;
    this.browserData = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenResolution: `${screen.width}x${screen.height}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      touchSupport: navigator.maxTouchPoints > 0,
    };
  }

  public static get instance(): LPAnalytics {
    if (!LPAnalytics._instance) {
      LPAnalytics._instance = new LPAnalytics();
    }

    return LPAnalytics._instance;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public logEvent(event: IEventLog) {
    this.events = [...this.events, event];
  }

  public async sendEvents() {
    try {
      const payload = {
        events: this.events,
        userId: this.userId,
        sessionId: this.sessionId,
        browserData: this.browserData,
      };

      await axios.post(this.URL, payload, {
        headers: { 'x-api-key': this.API_KEY },
      });

      this.events = [];
    } catch (e) {}
  }
}

export const setUser = (userId: string) => {
  LPAnalytics.instance.setUserId(userId);
};

export const logEvent = (eventName: string, data?: any) => {
  LPAnalytics.instance.logEvent({
    name: eventName,
    data,
    loggedAt: new Date().toISOString(),
  });

  LPAnalytics.instance.sendEvents();
};
