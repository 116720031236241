import { Countries } from './countriesList';
import { CurrencyType } from '../types';

export const BASE_CURRENCY: CurrencyType = 'USD';

export enum COMPENSATION_TYPE {
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const DEFAULT_MAX_COMPENSATION = {
  YEARLY: 1200000,
  MONTHLY: 100000,
  HOURLY: 10000,
};

export const COUNTRY_SPECIFIC_MAX_COMPENSATION: Partial<
  Record<Countries, { YEARLY: number; MONTHLY: number; HOURLY: number }>
> = {
  [Countries.India]: {
    YEARLY: 10000000,
    MONTHLY: 1000000,
    HOURLY: 10000,
  },
};

export const COUNTRIES_WITH_YEARLY_COMPENSATION = [
  Countries.India,
  Countries.UnitedStates,
];

export const COUNTRIES_WITH_CUSTOM_CURRENCY_INPUT: Partial<
  Record<Countries, { symbol: string; currency: CurrencyType }>
> = {
  [Countries.India]: {
    symbol: '₹',
    currency: 'INR',
  },
};

export const COMPENSATION_OPTIONS = [
  { type: COMPENSATION_TYPE.HOURLY, label: 'Hourly' },
  { type: COMPENSATION_TYPE.MONTHLY, label: 'Monthly' },
  { type: COMPENSATION_TYPE.YEARLY, label: 'Yearly' },
];

export enum CompensationBenefitType {
  Employment = 'Employment',
  Referral = 'Referral',
  Professional = 'Professional',
}

export const CompensationBenefitsOrder = [
  CompensationBenefitType.Employment,
  CompensationBenefitType.Referral,
  CompensationBenefitType.Professional,
];
