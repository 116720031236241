import React, { useRef } from 'react';
import { useEmojisplosion } from '../../../../../hooks';

import { Typography } from '@mui/material';
import { StepPanelHeader } from '../../../../../components';

import startImage from '../../../../../assets/passing-star.png';

export const VIUnderReview: React.FC = () => {
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  return (
    <>
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        Thank you for you application!
      </StepPanelHeader>

      <Typography variant="body1" data-testid="success-text">
        Our Talent Executive will review it and <br />
        reach out to you within 1-2 business days via email.
      </Typography>

      <Typography variant="body1" data-testid="success-text">
        Stay tuned.
      </Typography>
    </>
  );
};
