import React from 'react';
import { slideIn } from '../../../constants';
import { VideoPlayer } from 'src/components';
import { Box } from '@mui/material';

interface IProps {
  videoUrl: string;
  subtitleUrl: string;
  posterUrl: string;
  signature: string;
}

const SelectionVideoPlayer: React.FC<IProps> = ({
  videoUrl,
  subtitleUrl,
  posterUrl,
  signature,
}) => {
  return (
    <Box
      sx={(theme) => ({
        marginLeft: '67px',
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
          margin: 0,
        },
        [theme.breakpoints.up('lg')]: {
          minWidth: '300px',
        },
      })}
    >
      <VideoPlayer
        videoUrl={videoUrl}
        subtitleUrl={subtitleUrl}
        posterUrl={posterUrl}
        signature={signature}
        maxVideoWidth={{ sm: '8.5rem', lg: '8.5rem', xl: '10.5rem' }}
        minPlayerHeight={{ sm: 'auto', md: '16rem' }}
        sx={(theme) => ({
          alignItems: 'center',
          position: 'inherit',
          top: '-65px',
          [theme.breakpoints.down('lg')]: {
            top: 0,
          },
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
            animation: `${slideIn} 0.25s linear`,
            animationFillMode: 'both',
            animationDelay: '0.4s',
            top: '-16px !important',
            alignItems: 'center',
          },
        })}
        subTitlesContainerSx={() => ({
          alignItems: 'flex-start',
        })}
      />
    </Box>
  );
};

export default SelectionVideoPlayer;
