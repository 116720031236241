import { StepPanelHeader } from 'src/components';
import startImage from 'src/assets/passing-star.png';
import { Typography } from '@mui/material';

export const FinishedVILayout: React.FC = () => {
  return (
    <>
      <StepPanelHeader
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        We’re excited to know you better
      </StepPanelHeader>
      <Typography variant="body1" mb={0}>
        Our Talent Executive will review your application and reach out to you
        within <b>1-2 days</b> via email. Stay tuned!
      </Typography>
    </>
  );
};
