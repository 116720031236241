import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GoToNextStep, AuthUserConfirmation } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateEmail, userSelectors } from '../../redux/user';
import {
  authSelectors,
  authUser,
  clearAuthError,
  setAuthUserEmailSent,
  setSubmitProfileEmailSent,
} from '../../redux/auth';
import { usePersistUserAnswers } from '../../hooks';
import { isStatusLoading } from '../../utils';
import { EditOrResendEmailBlock } from '../profileReview/components';
import { validationSchema } from './components/validationSchema';
import SignInDialog from './components/SignInDialog';
import ConsentTermsDialog from 'src/components/ConsentTermsDialog/ConsentTermsDialog';
import { magicLinkCandidateSelectors } from '../../redux/magicLinkCandidate';
import { positionsSelectors } from '../../redux/positions';
import { massCampaignCandidateSelectors } from '../../redux/massCampaignCandidate';
import { logEvent } from 'src/services';
import { candidateSourceSelectors } from 'src/redux/candidateSource';

export const EmailForm: React.FC<{ title: string | ReactNode }> = ({
  title,
}) => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const zohoId = useAppSelector(
    magicLinkCandidateSelectors.getMagicLinkCandidateZohoId,
  );
  const position = useAppSelector(positionsSelectors.getPositionData);
  const massCampaignData = useAppSelector(
    massCampaignCandidateSelectors.getMassCampaignCandidateData,
  );
  const candidateSource = useAppSelector(
    candidateSourceSelectors.getCandidateSourceData,
  );
  const isEmailLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );
  const authApiStatus = useAppSelector(authSelectors.getAuthApiStatus);
  const isAuthUserEmailSent = useAppSelector(
    authSelectors.getIsAuthUserEmailSent,
  );

  const [savedEmail, setSavedEmail] =
    usePersistUserAnswers<Record<string, string>>('emailStep');

  const [isOpenSignInDialog, setIsOpenSignInDialog] = useState(false);
  const [isOpenConsentTermsDialog, setIsOpenConsentTermsDialog] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      email: userData?.email
        ? userData.email
        : savedEmail?.email
        ? savedEmail.email
        : '',
      isConsentChecked: savedEmail?.email ? true : false,
    },
    validationSchema,
    onSubmit: async (values) => {
      logEvent('launchpod-email-continue-click', {
        'Email Unconfirmed': formik.values.email,
      });

      if (userData) {
        dispatch(updateEmail({ ...values }));
        setSavedEmail({ email: formik.values.email });
      } else {
        dispatch(
          authUser({
            ...values,
            zohoId,
            position,
            massCampaignData,
            candidateSource,
          }),
        );
      }

      dispatch(setSubmitProfileEmailSent(false));
      dispatch(clearAuthError());
    },
  });

  useEffect(() => {
    logEvent('launchpod-email-page-loaded');

    return () => {
      dispatch(setAuthUserEmailSent(false));
      dispatch(clearAuthError());
    };
  }, []);

  useEffect(() => {
    setSavedEmail({ email: formik.values.email });
  }, [formik.values.email]);

  const sendEmailAction = useCallback(
    async (email: string) => {
      if (userData) {
        return await dispatch(updateEmail({ email })).unwrap();
      } else {
        return await dispatch(
          authUser({
            email,
            zohoId,
            position,
            massCampaignData,
            candidateSource,
          }),
        ).unwrap();
      }
    },
    [userData, zohoId, position, massCampaignData, candidateSource],
  );

  const handleLoginClick = () => {
    setIsOpenSignInDialog(true);
  };

  const handleModalClose = () => {
    setIsOpenSignInDialog(false);
  };

  return isAuthUserEmailSent ? (
    <>
      <AuthUserConfirmation email={formik.values.email} />
      <EditOrResendEmailBlock
        userEmail={formik.values.email}
        sendEmailAction={sendEmailAction}
        emailChangeAction={() => dispatch(setAuthUserEmailSent(false))}
        isLoading={isStatusLoading(authApiStatus) || isEmailLoading}
      />
    </>
  ) : (
    <Box>
      <Typography variant="h2" mb={4}>
        {title}
      </Typography>
      <TextField
        name="email"
        placeholder="Your email"
        label="Email"
        type="email"
        variant="outlined"
        autoFocus
        autoComplete="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        sx={(theme) => ({
          width: '400px',
          marginBottom: '8px',
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        })}
      />
      <Box mb={4}>
        <FormControlLabel
          control={
            <Checkbox
              onClick={formik.handleChange}
              checked={formik.values.isConsentChecked}
              name="isConsentChecked"
            />
          }
          label={
            <Typography
              variant="body1"
              onClick={(e) => e.preventDefault()}
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: {
                  whiteSpace: 'nowrap',
                },
              })}
            >
              I agree to{' '}
              <Link onClick={() => setIsOpenConsentTermsDialog(true)}>
                the personal data processing terms
              </Link>
            </Typography>
          }
          sx={{ mb: 0.5 }}
        />
        {formik.touched.isConsentChecked && formik.errors.isConsentChecked && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.palette.error.main,
              marginLeft: '2rem',
            })}
          >
            {formik.errors.isConsentChecked}
          </FormHelperText>
        )}
      </Box>

      <GoToNextStep
        isLoading={isStatusLoading(authApiStatus) || isEmailLoading}
        handleSubmitStep={formik.handleSubmit}
        textIdentifier={5}
      />

      {!userData?.email && (
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body1">Applied before?</Typography>
          <Link
            onClick={handleLoginClick}
            role="button"
            tabIndex={0}
            sx={{ cursor: 'pointer' }}
          >
            Log in
            <ArrowForwardIcon
              sx={{
                fontSize: '1rem',
                ml: '0.25rem',
              }}
            />
          </Link>
        </Box>
      )}
      <ConsentTermsDialog
        isOpen={isOpenConsentTermsDialog}
        handleClose={() => setIsOpenConsentTermsDialog(false)}
        handleConfirm={() => setIsOpenConsentTermsDialog(false)}
      />
      <SignInDialog
        isOpen={isOpenSignInDialog}
        handleClose={handleModalClose}
        initialValue={savedEmail?.email}
      />
    </Box>
  );
};
