import { Box } from '@mui/material';

export const CustomPagination: React.FC<{
  amount: number;
  selectedPage: number;
  onSelectPage: (selectedPage: number) => void;
}> = ({ amount, selectedPage, onSelectPage }) => {
  return (
    <Box display="flex" gap={1}>
      {new Array(amount).fill(null).map((_, idx) => (
        <Box
          key={idx}
          sx={(theme) => ({
            width: '10px',
            height: '10px',
            borderRadius: '100%',
            cursor: 'pointer',
            background:
              idx === selectedPage
                ? theme.palette.brand.secondary
                : theme.palette.brand.accent,
          })}
          onClick={() => onSelectPage(idx)}
        />
      ))}
    </Box>
  );
};
