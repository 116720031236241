/** @jsxImportSource @emotion/react */
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';

import { Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  PageTransitionWrapper,
  StepPanel,
  StepPanelHeader,
} from 'src/components';
import { ApplicationProgress } from 'src/features/ApplicationProgress/ApplicationProgress';
import startImage from 'src/assets/passing-star.png';

import { confirmIntermediateScreen, userSelectors } from 'src/redux/user';

export const FirstStepIsDone: React.FC = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  const handleClick = () => {
    dispatch(confirmIntermediateScreen());
  };

  if (userData?.crucialRequirements && !userData.crucialRequirementsSubmittedAt)
    return (
      <PageTransitionWrapper>
        <StepPanel alignItems="center" textAlign="center">
          <StepPanelHeader
            srcSetPng={startImage}
            alt="Star"
            data-testid="success-title"
          >
            Thank you for your answers!
          </StepPanelHeader>
          <Typography
            variant="body1"
            mb={0}
            data-testid="not-supported-by-open-positions-text"
          >
            First step is done, let’s continue your application.
          </Typography>
          <Flex width={{ xs: '100%', sm: 'auto' }}>
            <CustomButton
              onClick={handleClick}
              label="Continue"
              color="primary"
              isLoading={confirmIntermediateScreenLoading}
              fullWidth
            />
          </Flex>
        </StepPanel>
      </PageTransitionWrapper>
    );

  return (
    <PageTransitionWrapper>
      <StepPanel alignItems="center" textAlign="center" maxWidth="644px">
        <StepPanelHeader
          srcSetPng={startImage}
          alt="Star"
          data-testid="success-title"
        >
          We’re excited to know you better!
        </StepPanelHeader>
        <Typography
          variant="body1"
          mb={0}
          data-testid="not-supported-by-open-positions-text"
        >
          To make sure we’ll not miss your talent our team would like to invite
          you to ask you for a quick introduction and a short test.
        </Typography>
        <Flex width={{ xs: '100%', sm: 'auto' }}>
          <CustomButton
            onClick={handleClick}
            label="Continue"
            color="primary"
            isLoading={confirmIntermediateScreenLoading}
            fullWidth
          />
        </Flex>
        <ApplicationProgress />
      </StepPanel>
    </PageTransitionWrapper>
  );
};
