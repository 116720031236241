import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useIsMobileView } from 'src/hooks';
import {
  OverlayControls,
  SubtitlesContainer,
  VideoPlayerContainer,
} from './components';
import { IRenderVideoProps } from 'src/types';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
} from '@mui/system/styleFunctionSx';

export interface IVideoPlayerProps {
  videoUrl: string;
  subtitleUrl: string;
  posterUrl: string;
  signature: string;
  maxVideoWidth?: ResponsiveStyleValue<AllSystemCSSProperties['maxWidth']>;
  minPlayerHeight?: ResponsiveStyleValue<AllSystemCSSProperties['minHeight']>;
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
  subTitlesContainerSx?: (theme: Theme) => SystemStyleObject<Theme>;
}

export const VideoPlayer: FC<IVideoPlayerProps> = ({
  videoUrl,
  subtitleUrl,
  posterUrl,
  signature,
  maxVideoWidth = { sm: '62.5%', lg: '12.5rem', xl: '12.5rem' },
  minPlayerHeight = { sm: 'auto', md: '20rem' },
  sx,
  subTitlesContainerSx,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const subsRef = useRef<HTMLTrackElement | null>(null);
  const [currSubCue, setCurrSubCue] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const isMobileView = useIsMobileView();

  const handleVideoPause = () => {
    if (videoRef.current) {
      const video = videoRef.current;

      video.pause();
      setIsPaused(true);
    }
  };

  const handleVideoPlayPause = async () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (video.paused || video.ended) {
        await video.play();
        setIsPaused(false);
      } else {
        handleVideoPause();
      }
    }
  };

  const handleVideoReplay = async () => {
    if (videoRef.current) {
      const video = videoRef.current;
      video.currentTime = 0;
      setProgress(0);

      await video.play();
      setIsPaused(false);
    }
  };

  const handleVideoRewind = async () => {
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.currentTime > 0) {
        video.pause();
        setIsPaused(true);

        video.currentTime -= 10;

        await video.play();
        setIsPaused(false);
      }
    }
  };

  const handleProgressUpdate = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const currProgress = Math.floor(
        (video.currentTime / video.duration) * 100,
      );
      setProgress(currProgress);
    }
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  /**
   * Attach custom event handlers to progress bar
   * Dispose accordingly on unmount.
   */
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleProgressUpdate);
    }
    return () => {
      videoRef.current?.removeEventListener('timeupdate', handleProgressUpdate);
    };
  }, []);

  /**
   * Get current active subtitle track & project cue text into
   * custom container below video. Auto-update with 'cuechange' event.
   */
  useEffect(() => {
    const getSubtitles = (ev: Event) => {
      const activeCue = (ev.target as HTMLTrackElement)?.track
        .activeCues![0] as VTTCue;
      const subText = activeCue?.text;
      if (subText) {
        setCurrSubCue(subText);
      }
    };

    if (subsRef.current?.track) {
      if (!isMobileView) subsRef.current.track.mode = 'hidden';
      subsRef.current.addEventListener('cuechange', getSubtitles);
    }
    return () => {
      subsRef.current?.removeEventListener('cuechange', getSubtitles);
    };
  }, [subsRef.current]);

  const requestFullscreen = async () => {
    const video = videoRef.current as HTMLVideoElement & {
      webkitRequestFullscreen(): Promise<void>;
    };
    if (video) {
      if (video.requestFullscreen) {
        await video.requestFullscreen();
      } else if (video?.webkitRequestFullscreen) {
        await video.webkitRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenchange = async () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
        subsRef.current = null;
        setCurrSubCue('');
        handleVideoPause();
      }
    };

    videoRef.current?.addEventListener(
      'fullscreenchange',
      handleFullscreenchange,
    );

    videoRef.current?.addEventListener(
      'webkitendfullscreen',
      handleFullscreenchange,
    );

    return () => {
      videoRef.current?.removeEventListener(
        'fullscreenchange',
        handleFullscreenchange,
      );
      videoRef.current?.removeEventListener(
        'webkitendfullscreen',
        handleFullscreenchange,
      );
    };
  }, [videoRef.current]);

  useEffect(() => {
    if (isFullscreen) {
      requestFullscreen();
    }
  }, [isFullscreen, videoRef.current]);

  const renderVideo = (props?: IRenderVideoProps) => {
    const { autoPlay = true, isHidden = false } = props ?? {};
    return (
      <>
        <Box
          component="video"
          ref={videoRef}
          autoPlay={autoPlay}
          loop
          muted={isMuted}
          preload="auto"
          sx={{
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            filter: 'brightness(1.2)',
            display: isHidden ? 'none' : 'inherit',
          }}
          poster={posterUrl}
        >
          <source src={videoUrl} type="video/mp4"></source>
          <track
            ref={subsRef}
            kind="subtitles"
            srcLang="en"
            src={subtitleUrl}
          />
        </Box>
        <img
          src={posterUrl}
          style={{
            zIndex: '-1',
            position: 'absolute',
            top: '80%',
            height: '20%',
            width: '100%',
            filter: 'blur(16px) brightness(1.2)',
          }}
        />
      </>
    );
  };

  const renderMobilePlaceholder = (): JSX.Element => {
    return (
      <>
        {!isFullscreen && (
          <Box
            position="relative"
            onClick={async () => {
              await handleVideoPlayPause();
              setIsFullscreen(true);
            }}
          >
            <img
              src={posterUrl}
              alt={signature}
              style={{
                borderRadius: '50%',
                width: '110px',
                filter: 'brightness(1.2)',
                position: 'relative',
                zIndex: 1,
              }}
            />
            <img
              src={posterUrl}
              style={{
                zIndex: 0,
                position: 'absolute',
                left: 0,
                top: '80%',
                height: '16%',
                width: '100%',
                filter: 'blur(16px) brightness(1.2)',
              }}
            />
            <IconButton
              size="small"
              sx={(theme) => ({
                position: 'absolute',
                top: '72.5%',
                right: '3%',
                zIndex: 2,
                background: theme.palette.button.primary.front,
                color: theme.palette.text.invertedPrimary,
                boxShadow: `0 8px 12px -6px ${theme.palette.text.brand}`,
              })}
            >
              <PlayArrowIcon />
            </IconButton>
          </Box>
        )}

        {renderVideo({ autoPlay: false, isHidden: !isFullscreen })}
      </>
    );
  };

  return (
    <Box
      component="section"
      sx={(theme) => ({
        position: 'relative',
        gridColumn: 2,
        gridRow: '1/-1',
        width: '100%',
        minHeight: minPlayerHeight,
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          gridRow: '2/-1',
        },

        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto',
          gridColumn: '3/-1',
          gridRow: '2/4',
          flexDirection: 'column-reverse',
          alignSelf: 'end',
          alignItems: 'flex-end',
        },
        ...(sx ? sx(theme) : {}),
      })}
    >
      {isMobileView ? (
        renderMobilePlaceholder()
      ) : (
        <>
          <VideoPlayerContainer maxWidth={maxVideoWidth}>
            <CircularProgress
              id="progress-bar"
              variant="determinate"
              thickness={0.5}
              value={progress}
              sx={(theme) => ({
                position: 'absolute',
                zIndex: 2,
                height: '106% !important',
                width: '106% !important',
                color: theme.palette.highlight.progress,
              })}
            />
            <OverlayControls
              isPaused={isPaused}
              handleVideoRewind={handleVideoRewind}
              handleVideoPlayPause={handleVideoPlayPause}
              handleVideoReplay={handleVideoReplay}
            />
            {renderVideo()}
          </VideoPlayerContainer>
          <SubtitlesContainer
            isMuted={isMuted}
            subTitles={currSubCue}
            handleMuteToggle={handleMuteToggle}
            sx={subTitlesContainerSx}
          >
            <Typography
              variant="body2"
              color="secondary"
              fontSize="11px"
              margin="auto"
            >
              {signature}
            </Typography>
          </SubtitlesContainer>
        </>
      )}
    </Box>
  );
};
