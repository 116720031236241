import React from 'react';
import { Stack, Divider, Typography } from '@mui/material';
import { BasicDialog, CustomButton } from '..';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConsentTermsDialog: React.FC<IProps> = ({
  isOpen,
  handleClose,
  handleConfirm,
}) => {
  return (
    <BasicDialog isOpen={isOpen} handleClose={handleClose} maxWidth="740px">
      <Typography variant="h1" sx={{ mb: 4 }}>
        Consent on personal data processing
      </Typography>
      <Stack spacing={3}>
        <Typography variant="body1" component="p">
          This Consent on personal data processing (“Consent”) describes how
          Launch Pod as Personal Data controller and the companies related
          related to AgileEngine, LLC (hereinafter collectively “AgileEngine”)
          handles Personal Data (as defined below) and your rights with respect
          to processing of such Personal Data.
        </Typography>
        <Typography variant="body1" component="p">
          The processing of your Personal Data enables AgileEngine to assess
          of the correspondence of the proposal to the business requests
          of AgileEngine; to communicate the results of the consideration of the
          application and for further discussion of interaction; if the proposal
          becomes relevant after the summing up, AgileEngine can offer
          cooperation in the future; to provide you with the respective
          opportunities to give a proper access to the web-sites published
          by AgileEngine (inclusively LaunchPod), to communicate and support the
          candidates, following the provisions of applicable legislation.
        </Typography>
        <Typography variant="body1" component="p">
          Personal data: name, surname; e-mail; mobile telephone number
          (if applicable); city and state; LinkedIn data on your public profile
          (if applicable); resume.
        </Typography>
        <Typography variant="body1" component="p">
          AgileEngine may process the Personal Data in the course of any
          operation or set of operations which is performed on the Personal Data
          or on sets of the Personal Data, whether or not by automated means,
          such as collection, recording, organization, structuring, storage,
          adaptation or alteration, retrieval, consultation, use, disclosure
          by transmission, cross-border transfer, dissemination or otherwise
          making available
          <Typography variant="caption" component="sup" color="secondary">
            1
          </Typography>{' '}
          or combination, restriction, erasure or destruction.
        </Typography>
        <Typography variant="body1" component="p">
          I hereby also confirm on notifying me on my rights
          <Typography variant="caption" component="sup" color="secondary">
            2
          </Typography>{' '}
          exercised in relation to processing of personal data therefrom.
        </Typography>
        <Typography variant="body1" component="p">
          The period for which the Personal Data is stored is strictly limited
          and shall be conducted exclusively during the period necessary for the
          purposes mentioned above but no less than 3 (three) years.
        </Typography>
        <Typography variant="body1" component="p">
          If the consent given with this Consent is the legal basis for the
          processing of the Personal Data it can be withdrawn at any time.
          <Typography variant="caption" component="sup" color="secondary">
            3
          </Typography>
        </Typography>
        <Typography variant="body1" component="p">
          I hereby provide my consent to follow the rights and obligations
          mentioned in this Consent.
        </Typography>
        <Divider></Divider>
        <Typography variant="body2" component="p">
          [1] It presumes disclosure by transmission, dissemination or otherwise
          making available of the Personal Data to the legal entities within
          AgileEngine group of companies as well as the third parties, which
          AgileEngine concluded agreements with to follow the purposes
          of personal data processing under this consent (including foreign
          legal entities);
        </Typography>
        <Typography variant="body2" component="p">
          [2] Data subject is entitled to be provided with the following
          information: the identity and the contact details of the controller
          and, where applicable, of the controller’s representative; the contact
          details of the data protection officer, where applicable; the purposes
          of the processing for which the personal data are intended as well
          as the legal basis for the processing; the legitimate interests
          pursued by the controller or by a third party; the recipients
          or categories of recipients of the personal data, if any; the fact
          that the controller intends to transfer personal data to a third
          country; the period for which the personal data will be stored,
          or if that is not possible, the criteria used to determine that
          period; the existence of the right to request from the controller
          access to and rectification or erasure of personal data or restriction
          of processing concerning the data subject or to object to processing
          as well as the right to data portability; the existence of the right
          to withdraw consent at any time, without affecting the lawfulness
          of processing based on consent before its withdrawal; the right
          to lodge a complaint with a supervisory authority; the existence
          of automated decision-making, including profiling, and, at least
          in those cases, meaningful information about the logic involved,
          as well as the significance and the envisaged consequences of such
          processing for the data subject. The respective requests should
          be sent to: policy@agileengine.com;
        </Typography>
        <Typography variant="body2" component="p">
          [3] This will not affect the lawfulness of the processing of the
          Personal Data based on such consent before the withdrawal. The consent
          may be withdrawn by contacting policy@agileengine.com or directly
          in your account on LaunchPod. AgileEngine may be able to retain the
          Personal Data even if the consent is withdrawn and the Personal Data
          is processed on the other legal basis.
        </Typography>
        <CustomButton label="Close" onClick={handleConfirm} />
      </Stack>
    </BasicDialog>
  );
};

export default ConsentTermsDialog;
