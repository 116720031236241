import React, { useMemo } from 'react';
import { useAppSelector } from 'src/redux';
import { isPast } from 'date-fns';

import { PageTransitionWrapper } from 'src/components';
import { ScheduleTILayout } from './components/ScheduleTILayout';
import { TIScheduledLayout } from './components/TIScheduledLayout';
import { TIDoneLayout } from './components/TIDoneLayout';

import { userSelectors } from 'src/redux/user';

import { useUserMeetings } from 'src/hooks';

export const TechnicalInterviewPage: React.FC = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const { activeTechnicalInterview } = useUserMeetings();

  const Layout = useMemo(() => {
    if (
      activeTechnicalInterview &&
      isPast(new Date(activeTechnicalInterview.scheduledDate))
    )
      return TIDoneLayout;

    if (activeTechnicalInterview) {
      return TIScheduledLayout;
    }

    if (userData?.tiLink) {
      return ScheduleTILayout;
    }

    return null;
  }, [activeTechnicalInterview]);

  if (!Layout) return null;

  return (
    <PageTransitionWrapper>
      <Layout />
    </PageTransitionWrapper>
  );
};
