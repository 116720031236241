import React, { FC } from 'react';

import { Box, Tooltip, Typography, Zoom } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { CurrencyType } from 'src/types';

interface IProps {
  amount: string;
  rate: string;
  currency: CurrencyType;
  isShowRates?: boolean | false;
  isShowExchangeRate?: boolean | false;
  sx?: SxProps<Theme>;
}
export const CompensationRate: FC<IProps> = ({
  amount,
  rate,
  currency,
  isShowRates = false,
  isShowExchangeRate = false,
  sx,
}) => {
  return (
    <Box sx={sx}>
      <Zoom
        in={isShowRates || (!isShowRates && isShowExchangeRate)}
        style={{ transformOrigin: '0 0' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 1,
            py: 1,
          }}
        >
          {isShowExchangeRate && (
            <AutorenewIcon color="disabled" sx={{ fontSize: '1rem' }} />
          )}
          {isShowRates && (
            <Tooltip title={`1 USD ≈ ${rate} ${currency}`} placement="left">
              <Typography
                variant="body2"
                color={'text.secondary'}
                sx={{ letterSpacing: '0.02em' }}
              >
                {amount} {currency}
              </Typography>
            </Tooltip>
          )}
          {!isShowRates && isShowExchangeRate && (
            <Typography
              variant="body2"
              color={'text.secondary'}
              whiteSpace={'nowrap'}
            >
              1 USD ≈ {rate} {currency}
            </Typography>
          )}
        </Box>
      </Zoom>
    </Box>
  );
};
