import { useEffect } from 'react';
import { useIsStandalonePage } from './useIsStandalonePage';

import { useAppDispatch, useAppSelector } from '../redux';
import { userSelectors } from '../redux/user';
import { getActiveUserMeetings, meetingSelectors } from '../redux/meeting';

import { isStatusIdle, isStatusLoading } from '../utils';
import { MeetingType } from '../types';

export const useUserMeetings = (shouldFetch?: boolean) => {
  const dispatch = useAppDispatch();
  const isStandalonePage = useIsStandalonePage();

  const userData = useAppSelector(userSelectors.getUserData);
  const activeUserMeetingsApiStatus = useAppSelector(
    meetingSelectors.getMeetingsAPIStatus,
  );
  const activeUserMeetings = useAppSelector(meetingSelectors.getMeetingsData);
  const isPollingActiveMeeting = useAppSelector(
    meetingSelectors.getIsPollingActiveMeeting,
  );

  useEffect(() => {
    if (isStandalonePage || !shouldFetch) return;

    if (
      userData &&
      userData.profileSubmittedAt &&
      isStatusIdle(activeUserMeetingsApiStatus)
    ) {
      dispatch(getActiveUserMeetings());
    }
  }, [userData, activeUserMeetingsApiStatus, isStandalonePage, shouldFetch]);

  return {
    tiLink: userData?.tiLink,
    activeIntroCall:
      activeUserMeetings.find(
        (meeting) => meeting.type === MeetingType.INTRO_CALL,
      ) || null,
    activeTechnicalInterview:
      activeUserMeetings.find(
        (meeting) => meeting.type === MeetingType.TI_CALL,
      ) || null,
    isLoading: isStatusLoading(activeUserMeetingsApiStatus),
    activeUserMeetingsAPIStatus: activeUserMeetingsApiStatus,
    isPollingActiveMeeting,
  };
};
