import { PaletteOptions } from '@mui/material/styles/createPalette';
import { brandAccentColor } from './variables';

export default <PaletteOptions>{
  primary: {
    main: 'rgba(0, 0, 0, 1)',
  },
  error: {
    main: 'rgba(255, 46, 0, 1)',
  },
  success: {
    main: '#83C000',
  },
  secondary: {
    main: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(0, 0, 0, 0.5)',
    invertedPrimary: 'rgba(255, 255, 255, 1)',
    invertedSecondary: 'rgba(255, 255, 255, 0.8)',
    lightGrey: 'rgba(0, 0, 0, 0.38)',
    purple: '#BB28BE',
    brand: '#FF7A00',
  },
  brand: {
    accent: brandAccentColor,
    secondary: '#BB28BE',
  },
  button: {
    primary: {
      front: '#FA6400',
      frontHover: '#FF9853',
      frontFocus: '#FF9853',
      frontActive: '#FF9853',
      frontDisabled: '#F6F6F6',
      edge: '#E06F07',
      edgeHover: '#F09000',
      edgeFocus: '#F09000',
      edgeActive: '#F09000',
      edgeDisabled: '#DCDCDC',
      shadow: 'rgba(140, 67, 0, 0.2)',
      shadowHover: 'rgba(231, 112, 4, 0.2)',
      shadowFocus: 'rgba(231, 112, 4, 0.2)',
      shadowActive: 'rgba(231, 112, 4, 0.2)',
      text: '#FFFFFF',
      textDisabled: 'rgba(0, 0, 0, 0.5)',
    },
    secondary: {
      front: '#F4FBFE',
      frontHover: '#F5FCFF',
      frontFocus: '#FFF4CC',
      frontActive: '#FFF4CC',
      frontDisabled: '#F6F6F6',
      edge: '#C4E1ED',
      edgeHover: 'rgba(196, 225, 237, 0.8)',
      edgeFocus: '#F1E4B6',
      edgeActive: '#F1E4B6',
      edgeDisabled: '#DCDCDC',
      shadow: 'rgba(162, 195, 209, 0.16)',
      shadowHover: 'rgba(162, 195, 209, 0.12)',
      shadowFocus: 'rgba(241, 228, 182, 0.3)',
      shadowActive: 'rgba(241, 228, 182, 0.3)',
      text: '#000000',
      textDisabled: 'rgba(0, 0, 0, 0.5)',
    },
  },
  background: {
    brandAccent: brandAccentColor,
    brandSecondary: 'rgba(187, 40, 190, 1)',
    brandTertiary: 'rgba(130, 0, 133, 1)',
    brandTransparent: 'rgba(255, 189, 128, 0.2)',
    brandLinearGradient: 'linear-gradient(90deg, #FF7A00 50%, #FF9801 100%)',
    brandRadialGradient:
      'radial-gradient(37.64% 121.95% at 67.5% 57.31%, #FFD600 0%, rgba(255, 122, 0, 0) 100%)',
    inputDisabled: 'rgba(246, 246, 246, 1)',
    buttonHover: 'rgba(48, 48, 48, 1)',
    buttonHoverWhite: 'rgba(255, 255, 255, 0.15)',
    positionLink: 'rgba(255, 255, 255, 0.1)',
    socialLink: 'rgba(255, 255, 255, 0.1)',
    stepsCircle: 'rgba(255, 183, 77, 1)',
    backgroundPrimary: 'rgba(246, 248, 252, 1)',
    whiteLinearGradient:
      'linear-gradient(180deg, transparent 50%, #FFFFFF 100%)',
    overlay: 'rgba(0, 0, 0, 0.5)',
    overlayPanel: 'rgba(0, 0, 0, 0.8)',
    popoverBlue: 'rgba( 27, 139, 255, 255)',
    success: '#4CAF50',
  },
  highlight: {
    accent: '#fff4cc',
    actionable: '#f4fbfe',
    actionableHover: 'rgba(0, 118, 235, 0.1)',
    neutral: '#F6F8FC',
    hover: '#eff6f9',
    white: '#ffffff',
    error: '#ffdecc',
    textAccentShadow: `0px 0px 8px ${brandAccentColor}, 0px 0px 2px ${brandAccentColor}, 0px 0px 2px ${brandAccentColor}`,
    boxShadowAccent: 'rgba(185, 106, 32, 0.24)',
    fileSelection: '#0076eb',
    fileSelectionHover: '#016fdc',
    progress: 'rgb(255 217 77)',
  },
};
