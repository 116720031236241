import React from 'react';
import { CustomButton } from '../../../components';
import { Box, SvgIcon } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface IProps {
  handleStartInterview: () => void;
}

const StartRecordingButton: React.FC<IProps> = ({ handleStartInterview }) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: 260,
          [theme.breakpoints.down('xl')]: {
            width: 511,
          },
          position: 'relative',
          ['@media (max-width: 1200px)']: {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            width: 400,
          },
          ['@media (max-width: 400px)']: {
            width: '100%',
          },
        })}
      >
        <CustomButton
          label="Start recording"
          startIcon={<SvgIcon inheritViewBox component={PlayCircleIcon} />}
          fullWidth
          onClick={handleStartInterview}
          color="primary"
        />
      </Box>
    </>
  );
};

export default StartRecordingButton;
