/** @jsxImportSource @emotion/react */
import React from 'react';
import classnames from 'classnames';
import { ButtonUnstyled } from '@mui/base';
import { Box, ButtonBaseProps, CircularProgress } from '@mui/material';
import { useCustomButtonStyles } from './CustomButton.styles';

interface IProps extends ButtonBaseProps {
  href?: string;
  label?: string;
  testid?: string;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
  isCustomLoading?: boolean;
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
  maxWidth?: string;
  isSquare?: boolean;
}

export const CustomButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IProps
>(
  (
    {
      href,
      startIcon,
      endIcon,
      label,
      testid,
      onClick,
      isDisabled = false,
      isLoading = false,
      isCustomLoading = false,
      color = 'primary',
      fullWidth = false,
      maxWidth,
      isSquare = false,
      type,
      style,
    },
    ref,
  ) => {
    const styles = useCustomButtonStyles(color, fullWidth, isSquare, maxWidth);
    return (
      <ButtonUnstyled
        css={styles}
        className={classnames('ae-custom-button', {
          'is-loading': isLoading || isCustomLoading,
          'is-custom-loading': isCustomLoading,
        })}
        component={href ? 'a' : 'button'}
        onClick={onClick}
        disabled={isDisabled}
        href={href}
        ref={ref}
        type={type}
        data-testid={testid ?? ''}
        aria-label={isSquare ? label : ''}
        style={style}
      >
        <span className="ae-custom-button_shadow" />
        <span className="ae-custom-button_edge" />
        <Box className="ae-custom-button_front">
          {isLoading && (
            <Box
              className="ae-custom-button_loader"
              data-testid="loader-indicator"
            >
              <CircularProgress
                size="1rem"
                sx={{
                  color: color === 'primary' ? 'white' : 'black',
                }}
              />
            </Box>
          )}
          <Box className="ae-custom-button_text">
            {startIcon && (
              <Box className="ae-custom-button_start-icon">{startIcon}</Box>
            )}
            {!isSquare && <span>{label}</span>}
            {endIcon && (
              <Box className="ae-custom-button_end-icon">{endIcon}</Box>
            )}
          </Box>
        </Box>
      </ButtonUnstyled>
    );
  },
);
