import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { isPast } from 'date-fns';

import { Box, Stack, Typography } from '@mui/material';
import { BrandLogo, PageWrapper, ResponsiveBreak } from 'src/components';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';
import { RequestVideoInterviewLink } from './components/RequestVideoInterviewLink';

import { API_STATUS } from 'src/constants';
import {
  videoInterviewSelectors,
  viewUserVideoInterview,
} from 'src/redux/videoInterview';
import { isStatusLoading } from 'src/utils';
import LockerIcon from 'src/assets/locker.png';

export const VideoInterviewView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const {
    apiStatus: viewUserVideoInterviewApiStatus,
    data: viewUserVideoInterviewData,
  } = useAppSelector(videoInterviewSelectors.getUserVideoInterviewViewAPIData);

  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      dispatch(viewUserVideoInterview({ token }));
    }
  }, [token]);

  const parsedToken: {
    iat: number;
    exp: number;
    userVideoInterviewId: string;
  } | null = token ? JSON.parse(window.atob(token.split('.')[1])) : null;
  const isExpiredToken = !!parsedToken && isPast(new Date(parsedToken.exp));
  const userVideoInterviewId = parsedToken?.userVideoInterviewId || null;

  return (
    <PageWrapper isLoading={isStatusLoading(viewUserVideoInterviewApiStatus)}>
      <Stack
        sx={(theme) => ({
          background: theme.palette.background.backgroundPrimary,
          minWidth: '100%',
          minHeight: '100%',
        })}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            padding: '3rem 0 0 4.125rem',
          }}
        >
          <BrandLogo width={133} />
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="0.5rem"
          flex={1}
        >
          {viewUserVideoInterviewApiStatus === API_STATUS.COMPLETE &&
          viewUserVideoInterviewData ? (
            <VideoInterviewQuestions
              userVideoInterviewData={viewUserVideoInterviewData}
            />
          ) : (
            <></>
          )}
          {viewUserVideoInterviewApiStatus === API_STATUS.FAILED && (
            <>
              <img
                src={LockerIcon}
                style={{ height: '10.75rem', width: '10.75rem' }}
              />
              <Typography variant="h1">
                Looks like your secret link has expired
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                textAlign="center"
              >
                Your access to this video interview needs to be refreshed.
                <ResponsiveBreak breakpoint="sm" />
                Please contact the recruiter who provided it or use the button
                below.
              </Typography>
              <Box mt={3}>
                <RequestVideoInterviewLink
                  userVideoInterviewId={userVideoInterviewId}
                  isExpiredToken={isExpiredToken}
                />
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
