/** @jsxImportSource @emotion/react */
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';

import { Typography } from '@mui/material';
import {
  CustomButton,
  Flex,
  PageTransitionWrapper,
  StepPanel,
  StepPanelHeader,
} from 'src/components';
import { ApplicationProgress } from 'src/features/ApplicationProgress/ApplicationProgress';
import startImage from 'src/assets/passing-star.png';

import { confirmIntermediateScreen, userSelectors } from 'src/redux/user';
import { useUserTestTasks } from 'src/hooks';

export const TestTaskStepIsDone: React.FC = () => {
  const dispatch = useAppDispatch();

  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );
  const { passedTestTasks } = useUserTestTasks();

  const handleClick = () => {
    dispatch(confirmIntermediateScreen());
  };

  return (
    <PageTransitionWrapper>
      <StepPanel alignItems="center" textAlign="center" maxWidth="644px">
        <StepPanelHeader
          srcSetPng={startImage}
          alt="Star"
          data-testid="success-title"
        >
          Congratulations! <br />
          You’ve passed!
        </StepPanelHeader>
        <Typography
          variant="body1"
          mb={0}
          data-testid="not-supported-by-open-positions-text"
        >
          {passedTestTasks?.[0]?.score}% is a good score!
        </Typography>
        <Flex width={{ xs: '100%', sm: 'auto' }}>
          <CustomButton
            onClick={handleClick}
            label="Continue"
            color="primary"
            isLoading={confirmIntermediateScreenLoading}
            fullWidth
          />
        </Flex>
        <ApplicationProgress />
      </StepPanel>
    </PageTransitionWrapper>
  );
};
