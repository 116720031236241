import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';
import { feedbackStoreKey } from './feedback.const';

export const postFeedback = createAsyncThunk(
  `${feedbackStoreKey}/feedback`,
  async (
    body: {
      isLogged: boolean;
      feedback: string;
      page: string;
      userAgent: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(apiEndpoints.sendFeedback(), body, {
        withCredentials: body.isLogged,
      });

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
