import { Box, Theme, Typography } from '@mui/material';

import React, { FC } from 'react';
import { AdditionalInfoPanelWrapper } from './AdditionalInfoPanelWrapper';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { IVideoPlayerProps, VideoPlayer } from 'src/components';

interface IProps
  extends Pick<
    IVideoPlayerProps,
    'videoUrl' | 'subtitleUrl' | 'signature' | 'posterUrl'
  > {
  videoPlayerSx?: (theme: Theme) => SystemStyleObject<Theme>;
  rowOfImagesTitle: string;
  rowOfImages: { image: string; text: string }[];
  withVideoPlayer?: boolean | true;
}
const AdditionalInfoPanel: FC<IProps> = ({
  rowOfImagesTitle,
  rowOfImages,
  videoPlayerSx,
  withVideoPlayer = true,
  ...videoPlayerProps
}) => {
  return (
    <AdditionalInfoPanelWrapper data-testid="info-module-tt-selection">
      {withVideoPlayer && (
        <VideoPlayer
          {...videoPlayerProps}
          maxVideoWidth={{ sm: '8.5rem', lg: '8.5rem', xl: '10.5rem' }}
          minPlayerHeight={{ sm: 'auto', md: '16rem' }}
          sx={videoPlayerSx}
          subTitlesContainerSx={() => ({
            alignItems: 'flex-start',
            width: { md: '100%' },
            maxWidth: { md: '22rem' },
          })}
        />
      )}
      <Typography
        variant="body2"
        color="brand.secondary"
        fontWeight="600"
        mt={{ sm: 4, md: 3, lg: 3 }}
        mb={2}
      >
        {rowOfImagesTitle}
      </Typography>
      {rowOfImages.map((block, index) => {
        return (
          <Box key={index} display="flex" alignItems="flex-start" pb={2}>
            <Box
              display="flex"
              minWidth="3rem"
              justifyContent="center"
              fontSize="2rem"
            >
              {block.image}
            </Box>
            <Typography color="text.primary" variant="body1" px={2}>
              {block.text}
            </Typography>
          </Box>
        );
      })}
    </AdditionalInfoPanelWrapper>
  );
};

export default AdditionalInfoPanel;
