import { Box, Typography } from '@mui/material';
import { PageTransitionWrapper } from 'src/components';

import { EmploymentBenefit } from 'src/types';

export const EmploymentBenefits: React.FC<{
  data: EmploymentBenefit | null;
}> = ({ data }) => {
  if (!data) return null;

  return (
    <PageTransitionWrapper
      display="flex"
      flexDirection="column"
      gap={3}
      width="100%"
    >
      <Typography variant="h1" textAlign="center">
        {data.title}
      </Typography>

      {!!data.employmentOptions?.length && (
        <Box display="flex" gap={1} flexWrap="nowrap" overflow="auto">
          {data.employmentOptions.map((employmentOption) => (
            <Box
              key={employmentOption.label}
              sx={{
                background: '#FFF',
                borderRadius: '16px',
                padding: '24px 16px',
                display: 'flex',
                gap: '8px',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="h1">{employmentOption.label}</Typography>
              {!!employmentOption.sublabel && (
                <Typography variant="body2" color="text.secondary">
                  {employmentOption.sublabel}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      )}

      <Box display="flex" gap={1} flexWrap="nowrap" overflow="auto">
        {data.benefits.map((benefit) => (
          <Box
            key={benefit.label}
            sx={{
              background: '#FFF',
              borderRadius: '16px',
              padding: '24px 16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            {!!benefit.image && (
              <img
                src={benefit.image.url}
                alt={benefit.image.alt}
                width={benefit.image.width}
                height={benefit.image.height}
              />
            )}
            <Box display="flex" flexDirection="column" gap={1}>
              {!!benefit.label && <Typography>{benefit.label}</Typography>}
              {!!benefit.sublabel && (
                <Typography variant="body2" color="text.secondary">
                  {benefit.sublabel}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>

      {!!data.otherBenefits?.length && (
        <Box display="flex" gap={1} flexWrap="nowrap" overflow="auto">
          {data.otherBenefits.map((otherBenefit) => (
            <Box
              key={otherBenefit}
              sx={{
                background: '#FFF',
                borderRadius: '16px',
                padding: '24px 16px',
                display: 'flex',
                gap: '8px',
                flex: 1,
              }}
            >
              <Typography
                variant="body1"
                fontWeight={700}
                textAlign="center"
                width="100%"
              >
                {otherBenefit}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </PageTransitionWrapper>
  );
};
