import React from 'react';
import { useAppSelector } from 'src/redux';

import { Box, Typography } from '@mui/material';
import { BrandLogo } from 'src/components';
import { EmailForm } from 'src/features/EmailForm/EmailForm';
import { SignInPageFooter } from './components/Footer';
import { OverallStepsSignInPage } from './components/OverallSteps';

import { authSelectors } from 'src/redux/auth';

export const SignInPage: React.FC = () => {
  const isAuthUserEmailSent = useAppSelector(
    authSelectors.getIsAuthUserEmailSent,
  );

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.highlight.neutral,
        padding: '64px 24px',
      })}
      minHeight="100%"
      minWidth="100%"
      display="flex"
      flexDirection="column"
    >
      <BrandLogo width={94} />
      <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
        <Box
          sx={{
            background: '#FFF',
            borderRadius: '48px',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '980px',
          }}
        >
          <Box
            flex={1}
            display="flex"
            justifyContent="space-between"
            gap={2}
            sx={(theme) => ({
              padding: '56px 48px 32px 88px',

              [theme.breakpoints.down('lg')]: {
                padding: '32px 24px 16px 40px',
              },
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Box flex={1}>
              {!isAuthUserEmailSent && (
                <Typography variant="body2" color="text.secondary" mb={3}>
                  Start your journey at AgileEngine
                </Typography>
              )}
              <EmailForm title="Apply for a job and get instant feedback" />
            </Box>
            {!isAuthUserEmailSent && (
              <Box flex={1}>
                <Typography variant="body2" color="text.secondary" mb={3}>
                  Your application progress
                </Typography>
                <OverallStepsSignInPage />
              </Box>
            )}
          </Box>
          {!isAuthUserEmailSent && (
            <Box
              sx={(theme) => ({
                padding: '24px 48px 32px 88px',
                background: theme.palette.highlight.accent,
                width: '100%',
                [theme.breakpoints.down('lg')]: {
                  padding: '16px 32px 24px 40px',
                },
              })}
            >
              <SignInPageFooter />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
