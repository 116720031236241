import React, { useRef } from 'react';
import { Typography } from '@mui/material';
import { ResponsiveBreak, StepPanelHeader } from '../../../../../components';

import startImage from '../../../../../assets/passing-star.png';

export const DefaultMessage: React.FC = () => {
  const starRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <StepPanelHeader
        ref={starRef}
        srcSetPng={startImage}
        alt="Star"
        data-testid="success-title"
      >
        Thank you <ResponsiveBreak breakpoint="xs" />
        for your application!
      </StepPanelHeader>
      <Typography
        textAlign="center"
        variant="body1"
        data-testid="success-text"
        width="100%"
      >
        Our Talent Executive will reach out <ResponsiveBreak breakpoint="md" />
        to you to discuss the next steps.
      </Typography>
    </>
  );
};
