import { useCallback } from 'react';

import { Avatar, Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { IRecruiter } from 'src/types';
import { stringAvatar } from 'src/utils';

export const RecruiterProfile: React.FC<{ data: IRecruiter | null }> = ({
  data,
}) => {
  const onCopyClick = useCallback(() => {
    navigator.clipboard.writeText(data?.email ?? '');
  }, [data?.email]);

  if (!data || !data.isActive) return null;

  return (
    <Box>
      <Box
        sx={{
          background: '#FFF',
          borderRadius: '24px',
          padding: '28px 32px',
        }}
      >
        <Typography variant="body2" color="text.purple" textAlign="left" mb={2}>
          Meet your dedicated Talent Executive
        </Typography>
        <Box
          display="flex"
          gap={1.5}
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          {data.avatar ? (
            <Avatar
              alt={data.name}
              src={data.avatar}
              sx={{ width: '49px', height: '49px' }}
            />
          ) : (
            <Avatar
              {...stringAvatar(data.name)}
              sx={{ width: '49px', height: '49px' }}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flex={1}
          >
            <Typography textAlign="left">{data.name}</Typography>
            <Typography variant="body2" color="text.secondary" textAlign="left">
              {data.email}
            </Typography>
          </Box>
          <IconButton onClick={onCopyClick}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="body2" textAlign="left">
          If you have any questions or need assistance, feel free to reach out
          to {data.name.split(' ')[0]} directly.
        </Typography>
      </Box>
    </Box>
  );
};
