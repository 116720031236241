interface IPollRequestArg<T> {
  fn: () => Promise<T>;
  validate: (value: T) => boolean;
  interval: number;
  maxAttempts: number;
  finalCallback?: () => void;
  errorCallback?: () => void;
}

export const pollRequest = <T>({
  fn,
  validate,
  interval,
  maxAttempts,
  finalCallback,
  errorCallback,
}: IPollRequestArg<T>) => {
  let attempts = 0;

  const executePoll = async (
    resolve: (value: T) => void,
    reject: (value: unknown) => void,
  ) => {
    try {
      const result = await fn();
      attempts++;

      if (validate(result)) {
        finalCallback?.();
        return resolve(result);
      } else if (maxAttempts && attempts === maxAttempts) {
        errorCallback?.();
        return reject(new Error('Exceeded max attempts'));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    } catch (err) {
      return reject(new Error('Error'));
    }
  };

  return new Promise(executePoll);
};
