import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'src/redux';
import { useUserMeetings } from 'src/hooks';

import { Typography } from '@mui/material';
import { CustomButton, Flex, StepPanel, StepPanelHeader } from 'src/components';
import { PopupModal } from 'react-calendly';

import {
  getActiveUserMeetings,
  setIsPollingActiveMeeting,
} from 'src/redux/meeting';

import { isStatusComplete, isStatusLoading, pollRequest } from 'src/utils';

import { IMeeting, MeetingType } from 'src/types';

import startImage from 'src/assets/passing-star.png';
import { ReactComponent as CalendlyIcon } from 'src/assets/calendly-icon.svg';
import { ReachOutToUs } from './ReachOutToUs';

const ACTIVE_MEETINGS_POLL_INTERVAL = 10;
const ACTIVE_MEETINGS_POLL_ATTEMPTS = 3;

export const ScheduleTILayout = () => {
  const dispatch = useAppDispatch();

  const {
    tiLink,
    activeUserMeetingsAPIStatus,
    activeTechnicalInterview,
    isPollingActiveMeeting,
  } = useUserMeetings();

  const [isCallBooked, setIsCallBooked] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  const getUserActiveMeetings = useCallback(() => {
    return dispatch(getActiveUserMeetings()).unwrap();
  }, []);

  const pollUserActiveMeetings = () => {
    pollRequest<IMeeting[] | null>({
      fn: getUserActiveMeetings,
      validate: (value) => {
        const ti = value?.find((e) => e.type === MeetingType.TI_CALL);

        return ti?.id !== activeTechnicalInterview?.id;
      },
      interval: ACTIVE_MEETINGS_POLL_INTERVAL * 1000,
      maxAttempts: ACTIVE_MEETINGS_POLL_ATTEMPTS,
      errorCallback: () => {
        dispatch(setIsPollingActiveMeeting(false));
      },
      finalCallback: () => {
        dispatch(setIsPollingActiveMeeting(false));
      },
    });
  };

  const checkForCalendlySuccess = useCallback((e: MessageEvent) => {
    if (
      e.origin === 'https://calendly.com' &&
      e.data?.event === 'calendly.event_scheduled' &&
      e.data?.payload?.invitee?.uri
    ) {
      setShowCalendlyModal(false);
      setIsCallBooked(true);
      dispatch(setIsPollingActiveMeeting(true));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', checkForCalendlySuccess);
    return () => {
      window.removeEventListener('message', checkForCalendlySuccess);
    };
  }, [checkForCalendlySuccess]);

  useEffect(() => {
    if (
      isCallBooked &&
      !activeTechnicalInterview &&
      isStatusComplete(activeUserMeetingsAPIStatus)
    ) {
      pollUserActiveMeetings();
    }
  }, [activeTechnicalInterview, isCallBooked, activeUserMeetingsAPIStatus]);

  return (
    <>
      <StepPanel alignItems="center" textAlign="center" maxWidth="566px">
        <StepPanelHeader srcSetPng={startImage} alt="Star">
          Let’s talk!
        </StepPanelHeader>
        <Typography variant="body1" mb={0}>
          Based on your outstanding results, we would like to invite you to an
          interview.
        </Typography>
        <CustomButton
          color="primary"
          startIcon={
            <Flex bgcolor="white" borderRadius={2} p={0.5}>
              <CalendlyIcon />
            </Flex>
          }
          label="Book a time slot"
          onClick={() => {
            setShowCalendlyModal(true);
          }}
          isLoading={
            isPollingActiveMeeting ||
            isCallBooked ||
            isStatusLoading(activeUserMeetingsAPIStatus)
          }
        />
        <ReachOutToUs />
      </StepPanel>
      {tiLink && (
        <PopupModal
          url={tiLink}
          onModalClose={() => setShowCalendlyModal(false)}
          open={showCalendlyModal}
          rootElement={document.getElementById('root')!}
        />
      )}
    </>
  );
};
