import { EmploymentBenefit } from 'src/types';
import {
  COMPENSATION_TYPE,
  Countries,
  COUNTRY_SPECIFIC_MAX_COMPENSATION,
  DEFAULT_MAX_COMPENSATION,
} from 'src/constants';
import {
  dollarsImgURL,
  hotAirBalloonImgURL,
  newFolderImgURL,
} from 'src/constants/apiEndpoints';

export const getRatioForCountry = (country: string | null): number => {
  switch (country) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'USA':
      return 155.33;
    case 'USA':
      return 153.33;
    case 'India':
      return 149.33;
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
    case 'Spain':
      return 152;
    case 'Portugal':
    case 'Poland':
      return 164.67;
    case 'Ukraine':
      return 166;
    default:
    case undefined:
      return 155.33;
  }
};

export const convertToConversationType = (
  compensation: number | null,
  country: string | null,
  from: COMPENSATION_TYPE,
  to: COMPENSATION_TYPE,
) => {
  if (!compensation) {
    return 0;
  }

  if (!compensation || from === to) {
    return compensation;
  }
  const ratio = getRatioForCountry(country);

  if (from === COMPENSATION_TYPE.HOURLY) {
    compensation *= ratio;
    if (to === COMPENSATION_TYPE.YEARLY) {
      compensation *= 12;
    }
  }
  if (from === COMPENSATION_TYPE.MONTHLY) {
    if (to === COMPENSATION_TYPE.YEARLY) {
      compensation *= 12;
    } else {
      compensation /= ratio;
    }
  }
  if (from === COMPENSATION_TYPE.YEARLY) {
    compensation /= 12;
    if (to === COMPENSATION_TYPE.HOURLY) {
      compensation /= ratio;
    }
  }

  return compensation > 1 ? Math.round(compensation) : +compensation.toFixed(2);
};

export const getMaxCompensationValue = (
  compensationType: COMPENSATION_TYPE,
  country: Countries,
): number | null => {
  const countrySpecificCompensation =
    COUNTRY_SPECIFIC_MAX_COMPENSATION[country];
  let maxCompensation = null;
  switch (compensationType) {
    case COMPENSATION_TYPE.HOURLY:
      maxCompensation =
        countrySpecificCompensation?.HOURLY || DEFAULT_MAX_COMPENSATION.HOURLY;
      break;
    case COMPENSATION_TYPE.MONTHLY:
      maxCompensation =
        countrySpecificCompensation?.MONTHLY ||
        DEFAULT_MAX_COMPENSATION.MONTHLY;
      break;
    case COMPENSATION_TYPE.YEARLY:
      maxCompensation =
        countrySpecificCompensation?.YEARLY || DEFAULT_MAX_COMPENSATION.YEARLY;
      break;
    default:
      break;
  }
  return maxCompensation;
};

export const getCompensationMessage = (props: {
  monthlyExpectations: number | null;
}): string | null => {
  const { monthlyExpectations } = props;
  if (monthlyExpectations! >= 100000) {
    return 'OH COME ON!!!';
  } else if (monthlyExpectations! >= 5000) {
    return "Now we're talking!";
  } else if (monthlyExpectations! >= 2000) {
    return 'Sounds about right!';
  } else if (!monthlyExpectations) {
    return "You couldn't work for free";
  } else {
    return 'Ok!';
  }
};

export const getEmploymentBenefitsByLocation = (
  location: string | null,
): EmploymentBenefit | null => {
  switch (location) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'Peru':
      return {
        title: 'What you get as an independent contractor',
        employmentOptions: null,
        benefits: [
          {
            image: {
              url: dollarsImgURL,
              alt: 'dollars',
              width: '145px',
              height: '145px',
            },
            label: 'Competitive USD-based compensation',
            sublabel: 'Payments in local currency',
          },
          {
            image: {
              url: hotAirBalloonImgURL,
              alt: 'hotAirBalloon',
              width: '102px',
              height: '105px',
            },
            label: 'Remote work tailored to your lifestyle',
            sublabel: 'Hourly rate',
          },
          {
            image: {
              url: newFolderImgURL,
              alt: 'newFolder',
              width: '115px',
              height: '115px',
            },
            label: 'Self-managed accounting',
            sublabel: 'Supported by local experts',
          },
        ],
        otherBenefits: null,
      };
    case 'USA':
      return {
        title: 'What employment options we have',
        employmentOptions: [
          { label: '🤝 1099' },
          { label: '💼 W2' },
          { label: '⭐️ W2', sublabel: 'With benefits' },
        ],
        benefits: [
          {
            image: {
              url: dollarsImgURL,
              alt: 'dollars',
              width: '145px',
              height: '145px',
            },
            label: 'Competitive USD-based compensation',
            sublabel: 'Payments in local currency',
          },
          {
            image: {
              url: hotAirBalloonImgURL,
              alt: 'hotAirBalloon',
              width: '102px',
              height: '105px',
            },
            label: 'Remote work tailored to your lifestyle',
            sublabel: 'Hourly rate',
          },
          {
            image: {
              url: newFolderImgURL,
              alt: 'newFolder',
              width: '115px',
              height: '115px',
            },
            label: 'Self-managed accounting',
            sublabel: 'Supported by local experts',
          },
        ],
        otherBenefits: null,
      };
    case 'Spain':
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
      return {
        title: 'What you get as a B2B contractor',
        employmentOptions: null,
        benefits: [
          {
            image: {
              url: dollarsImgURL,
              alt: 'dollars',
              width: '145px',
              height: '145px',
            },
            label: 'Competitive USD-based compensation',
            sublabel: 'Payments in local currency',
          },
          {
            image: {
              url: hotAirBalloonImgURL,
              alt: 'hotAirBalloon',
              width: '102px',
              height: '105px',
            },
            label: 'Remote work tailored to your lifestyle',
            sublabel: 'Hourly rate',
          },
          {
            image: {
              url: newFolderImgURL,
              alt: 'newFolder',
              width: '115px',
              height: '115px',
            },
            label: 'Self-managed accounting',
            sublabel: 'Supported by local experts',
          },
        ],
        otherBenefits: ['🤝 Long-term B2B hiring cooperation'],
      };
    case 'Poland':
    case 'Portugal':
    case 'Ukraine':
      return {
        title: 'What you get as a B2B contractor',
        employmentOptions: null,
        benefits: [
          {
            image: {
              url: dollarsImgURL,
              alt: 'dollars',
              width: '145px',
              height: '145px',
            },
            label: 'Competitive USD-based compensation',
            sublabel: 'Payments in local currency',
          },
          {
            image: {
              url: hotAirBalloonImgURL,
              alt: 'hotAirBalloon',
              width: '102px',
              height: '105px',
            },
            label: 'Remote work tailored to your lifestyle',
            sublabel: 'Hourly rate',
          },
          {
            image: {
              url: newFolderImgURL,
              alt: 'newFolder',
              width: '115px',
              height: '115px',
            },
            label: 'Self-managed accounting',
            sublabel: 'Supported by local experts',
          },
        ],
        otherBenefits: ['🏖️ Paid time off included'],
      };
    case 'India':
      return {
        title: 'What you get as an independent employee',
        employmentOptions: null,
        benefits: [
          {
            image: {
              url: dollarsImgURL,
              alt: 'dollars',
              width: '145px',
              height: '145px',
            },
            label: 'Competitive USD-based compensation',
            sublabel: 'Payments in local currency',
          },
          {
            image: {
              url: hotAirBalloonImgURL,
              alt: 'hotAirBalloon',
              width: '102px',
              height: '105px',
            },
            label: 'Remote work tailored to your lifestyle',
            sublabel: 'Hourly rate',
          },
          {
            image: {
              url: newFolderImgURL,
              alt: 'newFolder',
              width: '115px',
              height: '115px',
            },
            label: 'Self-managed accounting',
            sublabel: 'Supported by local experts',
          },
        ],
        otherBenefits: ['🏖️ Paid time off included'],
      };
    default:
      return null;
  }
};

export const getReferralBenefitsByLocation = (
  location: string | null,
): string[] | null => {
  switch (location) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'Peru':
    case 'Spain':
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
      return [
        '<b>$1,000</b> for senior and lead-level developers',
        '<b>$700</b> for middle-level developers',
        '<b>$500</b> for manual testers and junior developers',
        '<b>$500</b> for back-office specialists',
        '<b>$250</b> for sourcing specialists',
      ];
    case 'USA':
      return [
        '<b>$2,500</b> for senior and lead-level developers',
        '<b>$1,500</b> for middle-level developers',
        '<b>$1000</b> for manual testers and junior developers',
        '<b>$1000</b> for back-office specialists',
      ];
    case 'Poland':
    case 'Portugal':
    case 'Ukraine':
    case 'India':
      return [
        '<b>$1,000</b> for senior and lead-level developers',
        '<b>$700</b> for middle-level developers',
        '<b>$500</b> for manual testers and junior developers',
        '<b>$500</b> for back-office specialists',
      ];
    default:
      return null;
  }
};

export const getProfessionalBenefitsByLocation = (
  location: string | null,
): string[] | null => {
  switch (location) {
    case 'Argentina':
    case 'Brazil':
    case 'Colombia':
    case 'Guatemala':
    case 'Mexico':
    case 'Peru':
    case 'Spain':
    case 'Bulgaria':
    case 'Romania':
    case 'Slovakia':
    case 'Slovenia':
    case 'Poland':
    case 'Portugal':
    case 'Ukraine':
      return [
        '<b>$300 per year</b> for self-development: education, team and individual sports, co-working services',
        'One-time <b>$300</b> for your home office setup',
        'Internal mentorship programs',
        "Performance evaluations every six months to ensure you're on track for success",
        'Personal growth roadmap tailored to your goals',
        'Skill-up workshops and tech talks hosted by our top software experts',
        'Opportunities to change projects, master new tech stacks, and switch teams',
      ];
    case 'India':
      return [
        '<b>$150 per year</b> for your self-development goals',
        'Internal mentorship programs',
        "Performance evaluations every six months to ensure you're on track for success",
        'Personal growth roadmap tailored to your goals',
        'Skill-up workshops and tech talks hosted by our top software experts',
        'Opportunities to change projects, master new tech stacks, and switch teams',
      ];
    default:
      return null;
  }
};
