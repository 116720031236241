export interface IOverallStep {
  label: string;
  description?: string;
  time?: string;
}

export const OVERALL_STEPS: IOverallStep[] = [
  {
    label: 'Tell us about yourself',
    time: '2 minutes',
  },
  { label: 'Confirm requirements', time: '1 sec!' },
  {
    label: 'Pass a short test',
    time: '30-60 min',
  },
  {
    label: 'Record a short video',
    description:
      'Introduce yourself on a video, instead of waiting for an interview ',
    time: '5 min',
  },
  {
    label: 'Ace the interview with our manager',
    description:
      'Schedule a call yourself right away after your video is reviewed',
  },
  {
    label: 'Get an offer',
    time: 'As quick as possible',
  },
];
