import React from 'react';
import { CustomButton } from '../../../components';
import { Box, SvgIcon } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';

interface IProps {
  handleNextQuestion: () => void;
}

const ProceedToTheNexQuestionButton: React.FC<IProps> = ({
  handleNextQuestion,
}) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: 260,
          position: 'relative',
          [theme.breakpoints.up('xxl')]: {
            width: 600,
          },
          ['@media (max-width: 1200px)']: {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            width: 400,
          },
          ['@media (max-width: 400px)']: {
            width: '100%',
          },
        })}
      >
        <CustomButton
          label="Next question"
          startIcon={<SvgIcon inheritViewBox component={SkipNextIcon} />}
          fullWidth
          onClick={handleNextQuestion}
          color="primary"
        />
      </Box>
    </>
  );
};

export default ProceedToTheNexQuestionButton;
