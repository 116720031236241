import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const SignInPageFooter: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    <Box>
      <Typography>
        New to AgileEngine? Learn more about{' '}
        <Link
          href="https://www.join.agileengine.com/"
          target="_blank"
          sx={(theme) => ({
            color: theme.palette.text.brand,
            textDecorationColor: theme.palette.text.brand,
          })}
        >
          working with us
        </Link>{' '}
        👋
      </Typography>
    </Box>
    <Box display="flex" gap={1}>
      <Typography variant="body2">
        <Link component={RouterLink} to="/terms-of-use" color="text.lightGrey">
          Terms of use
        </Link>
      </Typography>
      <Typography variant="body2" color="text.lightGrey">
        •
      </Typography>
      <Typography variant="body2">
        <Link
          href="https://agileengine.com/privacy-policy"
          target="_blank"
          color="text.lightGrey"
        >
          Privacy policy
        </Link>
      </Typography>
    </Box>
  </Box>
);
