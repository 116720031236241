import React from 'react';
import { Route, Routes as RoutesWrapper, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../constants';
import MainPage from '../pages/mainPage/MainPage';
import NotFound from '../pages/notFound/NotFound';
import Name from '../features/name/Name';
import Country from '../features/country/Country';
import City from '../features/city/City';
import EnglishLevel from '../features/englishLevel/EnglishLevel';
import TechSkills from '../features/techSkills/TechSkills';
import Experience from '../features/experience/Experience';
import Seniority from '../features/seniority/Seniority';
import NoticePeriod from '../features/noticePeriod/NoticePeriod';
import ProfileReview from '../features/profileReview/ProfileReview';
import RouteGuard from './RouteGuard';
import Engagement from '../features/engagement/Engagement';
import ProfessionalIdentity from '../features/professionalIdentity/ProfessionalIdentity';
import TestTaskSelection from '../pages/TestTaskSelection/TestTaskSelection';
import VideoInterviewSelection from '../pages/VideoInterviewSelection/VideoInterviewSelection';
import RecordVideo from '../pages/RecordVideo/RecordVideo';
import ApplicationSuccess from '../features/applicationSuccess/ApplicationSuccess';
import UnsubscribeFromReminders from '../pages/UnsubscribeFromReminders/UnsubscribeFromReminders';
import ResolveUserDuplication from '../features/resolveUserDuplication/ResolveUserDuplication';
import InternalTestTaskSentStep from '../features/InternalTestTaskSentStep/InternalTestTaskSentStep';
import WorkArea from '../features/techSkills/components/WorkArea';
import Specialization from '../features/techSkills/components/Specialization';
import PrimarySkills from '../features/techSkills/components/PrimarySkills';
import SecondarySkills from '../features/techSkills/components/SecondarySkills';
import QuizTestTask from '../pages/QuizTestTask/QuizTestTask';
import TermsOfUse from '../features/termsOfUse/TermsOfUse';
import InternalTestTask from '../pages/InternalTestTask/InternalTestTask';
import Office from '../features/office/Office';
import ReferFriend from '../pages/ReferFriend/ReferFriend';
import DeclineReferral from '../pages/DeclineReferral/DeclineReferral';
import UnsubscribeReferral from 'src/pages/UnsubscribeReferral/UnsubscribeReferral';
import LongTimeNoSeeStep from 'src/pages/LongTimeNoSee/LongTimeNoSee';
import AboutYourself from '../features/aboutYourself/AboutYourself';
import { TechnicalInterviewPage } from 'src/pages/TechnicalInterview/TechnicalInterview';
import { KnowledgeQuizPage } from 'src/pages/KnowledgeQuiz/KnowledgeQuizPage';
import {
  AEBenefitsPage,
  CompensationPage,
  CrucialRequirements,
  EmailPage,
  FirstStepIsDone,
  IntroCallPage,
  OfferPage,
  SignInPage,
  TestTaskStepIsDone,
  VIStepIsDone,
} from 'src/pages';
import SuccessfulPositionApplication from '../features/successfulPositionApplication/SuccessfulPositionApplication';
import { VideoInterviewView } from 'src/pages/VideoInterviewView/VideoInterviewView';
import { CancelApplication } from 'src/pages/CancelApplication/CancelApplication';
import CodilityTestTaskPage from 'src/pages/CodilityTestTask/CodilityTestTask';
import { JobOpeningCrucialRequirementCategory } from 'src/types';

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <RoutesWrapper location={location} key={location.pathname}>
      <Route path="/">
        <Route index element={<SignInPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path={APP_ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route
          path={APP_ROUTES.SUCCESSFUL_POSITION_APPLICATION}
          element={<SuccessfulPositionApplication />}
        />
        <Route
          path={APP_ROUTES.UNSUBSCRIBE_FROM_REMINDERS}
          element={<UnsubscribeFromReminders />}
        />
        <Route path={APP_ROUTES.REFER_FRIEND} element={<ReferFriend />} />
        <Route
          path={APP_ROUTES.DECLINE_REFERRAL}
          element={<DeclineReferral />}
        />
        <Route
          path={APP_ROUTES.UNSUBSCRIBE_REFERRAL}
          element={<UnsubscribeReferral />}
        />
        <Route
          path={APP_ROUTES.VIDEO_INTERVIEW_VIEW}
          element={<VideoInterviewView />}
        />

        <Route
          element={
            <RouteGuard>
              <MainPage />
            </RouteGuard>
          }
        >
          <Route path={APP_ROUTES.EMAIL} element={<EmailPage />} />
          <Route path={APP_ROUTES.NAME} element={<Name />} />
          <Route
            path={APP_ROUTES.PROFESSIONAL_IDENTITY}
            element={<ProfessionalIdentity />}
          />
          <Route path={APP_ROUTES.COUNTRY} element={<Country />} />
          <Route path={APP_ROUTES.CITY} element={<City />} />
          <Route path={APP_ROUTES.OFFICE} element={<Office />} />
          <Route path={APP_ROUTES.ENGLISH_LEVEL} element={<EnglishLevel />} />
          <Route path={APP_ROUTES.TECH_SKILLS} element={<TechSkills />}>
            <Route index element={<WorkArea />} />
            <Route
              path={APP_ROUTES.SPECIALIZATION}
              element={<Specialization />}
            />
            <Route
              path={APP_ROUTES.PRIMARY_SKILLS}
              element={<PrimarySkills />}
            />
            <Route
              path={APP_ROUTES.SECONDARY_SKILLS}
              element={<SecondarySkills />}
            />
          </Route>
          <Route path={APP_ROUTES.EXPERIENCE} element={<Experience />} />
          <Route path={APP_ROUTES.SENIORITY} element={<Seniority />} />
          <Route path={APP_ROUTES.ENGAGEMENT} element={<Engagement />} />
          <Route
            path={APP_ROUTES.COMPENSATION}
            element={<CompensationPage />}
          />
          <Route path={APP_ROUTES.NOTICE_PERIOD} element={<NoticePeriod />} />
          <Route path={APP_ROUTES.PROFILE_REVIEW} element={<ProfileReview />} />
          <Route path={APP_ROUTES.ABOUT_YOURSELF} element={<AboutYourself />} />
          <Route
            path={APP_ROUTES.FIRST_STEP_IS_DONE}
            element={<FirstStepIsDone />}
          />
          <Route path={APP_ROUTES.BENEFITS} element={<AEBenefitsPage />} />
          <Route
            path={APP_ROUTES.LONG_TIME_NO_SEE}
            element={<LongTimeNoSeeStep />}
          />
          <Route
            path={APP_ROUTES.INTERNAL_TEST_TASK_SENT}
            element={<InternalTestTaskSentStep />}
          />
          <Route
            path={APP_ROUTES.VIDEO_INTERVIEW_SELECTION}
            element={<VideoInterviewSelection />}
          />
          <Route
            path={APP_ROUTES.VIDEO_INTERVIEW_FINISHED}
            element={<VIStepIsDone />}
          />
          <Route path={APP_ROUTES.RECORD_VIDEO} element={<RecordVideo />} />

          <Route
            path={`${APP_ROUTES.KNOWLEDGE_QUIZ}`}
            element={<KnowledgeQuizPage />}
          />
          <Route
            path={`${APP_ROUTES.KNOWLEDGE_QUIZ}/:quizQuestionId`}
            element={<KnowledgeQuizPage />}
          />

          <Route
            path={APP_ROUTES.TEST_TASK_SELECTION}
            element={<TestTaskSelection />}
          />
          <Route
            path={`${APP_ROUTES.INTERNAL_TEST_TASK}`}
            element={<InternalTestTask />}
          />
          <Route
            path={`${APP_ROUTES.CODILITY_TEST_TASK}`}
            element={<CodilityTestTaskPage />}
          />
          <Route
            path={`${APP_ROUTES.QUIZ_TEST_TASK}`}
            element={<QuizTestTask />}
          />
          <Route
            path={`${APP_ROUTES.QUIZ_TEST_TASK}/:quizQuestionId`}
            element={<QuizTestTask />}
          />
          <Route
            path={APP_ROUTES.TEST_TASK_FINISHED_FINISHED}
            element={<TestTaskStepIsDone />}
          />

          <Route
            path={`${APP_ROUTES.RESOLVE_USER_DUPLICATION}/:token`}
            element={<ResolveUserDuplication />}
          />

          <Route
            path={APP_ROUTES.APPLICATION_SUCCESS}
            element={<ApplicationSuccess />}
          />

          <Route
            path={APP_ROUTES.SKILLS_REQUIREMENTS}
            element={
              <CrucialRequirements
                category={JobOpeningCrucialRequirementCategory.Technology}
              />
            }
          />
          <Route
            path={APP_ROUTES.EDUCATION_REQUIREMENTS}
            element={
              <CrucialRequirements
                category={JobOpeningCrucialRequirementCategory.Education}
              />
            }
          />
          <Route
            path={APP_ROUTES.VISA_REQUIREMENTS}
            element={
              <CrucialRequirements
                category={JobOpeningCrucialRequirementCategory.Visa}
              />
            }
          />

          <Route path={APP_ROUTES.INTRO_CALL} element={<IntroCallPage />} />

          <Route
            path={APP_ROUTES.TECHNICAL_INTERVIEW}
            element={<TechnicalInterviewPage />}
          />

          <Route path={APP_ROUTES.OFFER} element={<OfferPage />} />
          {/* Independent vi path */}
          <Route
            path={APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_START}
            element={<VideoInterviewSelection />}
          />
          <Route
            path={APP_ROUTES.CANDIDATE_VIDEO_INTERVIEW_RECORD}
            element={<RecordVideo />}
          />
          <Route
            path={APP_ROUTES.CANCEL_APPLICATION}
            element={<CancelApplication />}
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </RoutesWrapper>
  );
};

export default Routes;
