/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { isEmpty } from 'lodash';

import { Box, Typography } from '@mui/material';
import { CustomButton, CustomPagination } from 'src/components';
import { EmploymentBenefits } from './components/EmploymentBenefits';
import { ReferralBenefits } from './components/ReferralBenefits';
import { ProfessionalBenefits } from './components/ProfessionalBenefits';

import { confirmIntermediateScreen, userSelectors } from 'src/redux/user';
import {
  getEmploymentBenefitsByLocation,
  getProfessionalBenefitsByLocation,
  getReferralBenefitsByLocation,
} from 'src/utils';
import {
  CompensationBenefitsOrder,
  CompensationBenefitType,
} from 'src/constants';

export const AEBenefitsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData);
  const confirmIntermediateScreenLoading = useAppSelector(
    userSelectors.getIsUserPerformingAction,
  );

  const [currentBenefits, setCurrentBenefits] = useState(0);

  const benefitsData = {
    employmentBenefits: getEmploymentBenefitsByLocation(
      userData?.country || null,
    ),
    referralBenefits: getReferralBenefitsByLocation(userData?.country || null),
    professionalBenefits: getProfessionalBenefitsByLocation(
      userData?.country || null,
    ),
  };

  useEffect(() => {
    if (isEmpty(benefitsData)) {
      dispatch(confirmIntermediateScreen());
    }
  }, [benefitsData]);

  const availableBenefits = CompensationBenefitsOrder.filter(
    (benefitType) =>
      (benefitType === CompensationBenefitType.Employment &&
        !!benefitsData.employmentBenefits) ||
      (benefitType === CompensationBenefitType.Referral &&
        !!benefitsData.referralBenefits) ||
      (benefitType === CompensationBenefitType.Professional &&
        !!benefitsData.professionalBenefits),
  );

  const isLastBenefits = useMemo(
    () => currentBenefits === availableBenefits.length - 1,
    [currentBenefits, availableBenefits],
  );
  const handleClickNext = useCallback(() => {
    if (isLastBenefits) {
      dispatch(confirmIntermediateScreen());
    } else {
      setCurrentBenefits((currBenefits) => currBenefits + 1);
    }
  }, [isLastBenefits]);

  const currentBenefitType = availableBenefits[currentBenefits];

  if (
    !benefitsData.employmentBenefits &&
    !benefitsData.referralBenefits &&
    !benefitsData.professionalBenefits
  )
    return null;

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.highlight.neutral,
        padding: '48px 24px 72px 24px',
        borderRadius: '48px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
      })}
    >
      <Typography variant="body2" color="text.purple">
        Working with AgileEngine
      </Typography>
      {currentBenefitType === CompensationBenefitType.Employment && (
        <EmploymentBenefits data={benefitsData.employmentBenefits} />
      )}
      {currentBenefitType === CompensationBenefitType.Referral && (
        <ReferralBenefits data={benefitsData.referralBenefits} />
      )}
      {currentBenefitType === CompensationBenefitType.Professional && (
        <ProfessionalBenefits data={benefitsData.professionalBenefits} />
      )}
      <CustomButton
        onClick={handleClickNext}
        label={isLastBenefits ? 'Continue' : 'Next'}
        isLoading={confirmIntermediateScreenLoading}
      />
      <CustomPagination
        amount={availableBenefits.length}
        selectedPage={currentBenefits}
        onSelectPage={(selectedPage) => setCurrentBenefits(selectedPage)}
      />
    </Box>
  );
};
