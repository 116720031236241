/** @jsxImportSource @emotion/react */
import React from 'react';
import { useAppSelector } from 'src/redux';

import {
  PageTransitionWrapper,
  RecruiterProfile,
  StepPanel,
} from 'src/components';
import { FinishedVILayout } from './components/FinishedVILayout';
import { SkippedVILayout } from './components/SkippedVILayout';
import { IncompleteVILayout } from './components/IncompleteVILayout';
import { ApplicationProgress } from 'src/features/ApplicationProgress/ApplicationProgress';

import { userSelectors } from 'src/redux/user';
import { useUserVideoInterview } from 'src/hooks';

export const VIStepIsDone: React.FC = () => {
  const user = useAppSelector(userSelectors.getUserData);

  const {
    isUserCompletedVideoInterview,
    isUserSkippedVideoInterview,
    isUserQuitedVideoInterview,
  } = useUserVideoInterview();

  return (
    <PageTransitionWrapper>
      <StepPanel alignItems="center" textAlign="center" maxWidth="644px">
        {isUserCompletedVideoInterview && <FinishedVILayout />}
        {isUserSkippedVideoInterview && <SkippedVILayout />}
        {isUserQuitedVideoInterview && <IncompleteVILayout />}
        <RecruiterProfile data={user?.recruiter || null} />
        <ApplicationProgress />
      </StepPanel>
    </PageTransitionWrapper>
  );
};
