import React, { useRef, useEffect, useState } from 'react';
import RecordRTC from 'recordrtc';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/css/videojs.record.css';
import { styled, Box, Typography, SvgIcon } from '@mui/material';
import { ReactComponent as VideoCameraIcon } from '../../../assets/video-camera-icon.svg';
import { VideoInterviewQuestionStatus } from '../../../types';
import Player from 'video.js/dist/types/player';

const Timer = styled(Box)(() => ({
  transform: 'translate(-50%, -50%)',
  animation: 'anim 1s ease-in-out infinite',
  '@keyframes anim': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(8)',
      opacity: 0,
    },
  },
}));

const VideoRecordWrapper = styled('div')({
  position: 'relative',
  '.video-js': {
    borderRadius: '10px',
    width: 511,
    height: 279,
    '& .vjs-progress-control': {
      marginRight: '15px',
    },
    '& .vjs-control-bar': {
      background:
        'linear-gradient(0deg, rgba(0,0,0,1) 18%, rgba(255,255,255,0) 100%)',
    },
  },
  '.vjs-record.vjs-device-button.vjs-control': {
    display: 'none',
  },
  '.vjs-tech': {
    borderRadius: '10px',
    objectFit: 'cover',
  },
  ['@media (max-width: 1200px)']: {
    width: '100%',
    '.video-js': {
      width: '100%',
    },
  },
  ['@media (max-width: 820px)']: {
    display: 'flex',
    justifyContent: 'center',
    background: '#F4FBFE',
    '.video-js': {
      height: 377,
      width: 320,
    },
  },
  ['@media (max-width: 425px)']: {
    '.video-js': {
      height: 250,
      width: 200,
    },
  },
});

const getSupportedVideoMimeType = () => {
  if (MediaRecorder.isTypeSupported('video/mp4')) {
    return 'video/mp4';
  } else if (MediaRecorder.isTypeSupported('video/webm')) {
    return 'video/webm';
  }
  return null;
};

interface IProps {
  onReady: (player: any) => void;
  deviceIsReady: boolean;
  deviceIsBlocked: boolean;
  duration: number;
  questionStatus: VideoInterviewQuestionStatus;
}

export const VideoJSComponent: React.FC<IProps> = ({
  onReady,
  deviceIsReady,
  deviceIsBlocked,
  duration,
  questionStatus,
}) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const [loadingTime, setLoadingTime] = useState(3);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (questionStatus === VideoInterviewQuestionStatus.LOADING) {
      interval = setInterval(() => {
        setLoadingTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      setLoadingTime(3);
    }

    return () => clearInterval(interval);
  }, [questionStatus]);

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    } else {
      const player = playerRef.current;
      (player as any).record().getDevice();
    }
  }, [videoRef]);

  useEffect(() => {
    return disposePlayer;
  }, [playerRef]);

  useEffect(() => {
    if (playerRef.current && duration) {
      disposePlayer();
      createPlayer();
    }
  }, [duration]);

  const createPlayer = () => {
    const videoJsOptions = {
      autoplay: true,
      controls: false,
      bigPlayButton: false,
      fluid: false,
      controlBar: {
        playToggle: true,
        recordToggle: false,
        fullscreenToggle: false,
      },
      plugins: {
        record: {
          audio: true,
          video: true,
          maxLength: duration * 60,
          displayMilliseconds: false,
          debug: true,
          timeSlice: 5000,
          videoMimeType: getSupportedVideoMimeType(),
        },
      },
    };

    const videoElement = document.createElement('video-js');

    videoElement.className = 'video-js vjs-default-skin';
    (videoRef.current as any).appendChild(videoElement);

    const player = ((playerRef.current as any) = videojs(
      videoElement,
      videoJsOptions,
      () => {
        const version_info =
          'Using video.js ' +
          videojs.VERSION +
          ' with videojs-record ' +
          videojs.getPluginVersion('record') +
          ', recordrtc ' +
          RecordRTC.version +
          ' and React ' +
          React.version;
        videojs.log(version_info);

        onReady(player);
      },
    ));
  };

  const disposePlayer = () => {
    const player = playerRef.current;

    if (player && !(player as any).isDisposed()) {
      (player as any).dispose();
      playerRef.current = null;
    }
  };

  return (
    <VideoRecordWrapper data-vjs-player>
      <div ref={videoRef} />
      {!deviceIsReady && (
        <Box
          sx={{
            color: 'white',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <SvgIcon
            inheritViewBox
            component={VideoCameraIcon}
            sx={(theme) => ({
              width: '20px',
              path: {
                fill: deviceIsBlocked ? '#FF4C4C' : theme.palette.brand.accent,
              },
            })}
          />
          {deviceIsBlocked ? (
            <>
              <Typography variant="h3" mb={2} sx={{ color: 'white' }}>
                Your camera is blocked
              </Typography>
              <Typography variant="body2" mb={3}>
                Click the camera icon in your browser's <br />
                address bar to configure permissions <br />
                so we can see and hear you.
              </Typography>
            </>
          ) : (
            <Typography variant="body2" mb={3}>
              Please allow access <br />
              to your camera and microphone <br />
              so we can see and hear you
            </Typography>
          )}
        </Box>
      )}
      {questionStatus === VideoInterviewQuestionStatus.LOADING && (
        <Box
          sx={{
            color: 'white',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#00000094',
            fontSize: '3rem',
            ['@media (max-width: 820px)']: {
              width: 320,
            },
          }}
        >
          <Timer>{loadingTime}</Timer>
        </Box>
      )}
    </VideoRecordWrapper>
  );
};

export default VideoJSComponent;
