import { useNavigate } from 'react-router-dom';

import { CustomButton, StepPanelHeader } from 'src/components';
import { Typography } from '@mui/material';
import coffeeImage from 'src/assets/coffee.png';

import { APP_ROUTES } from 'src/constants';

export const SkippedVILayout: React.FC = () => {
  const navigate = useNavigate();

  const handleSelectVideoInterview = () => {
    navigate(`/${APP_ROUTES.VIDEO_INTERVIEW_SELECTION}`);
  };

  return (
    <>
      <StepPanelHeader
        srcSetPng={coffeeImage}
        alt="Video Interview Is Skipped!"
      >
        Async video interview skipped
      </StepPanelHeader>
      <Typography variant="body1">
        Our Talent Executive will review your application once it’s finished.
      </Typography>
      <CustomButton
        onClick={handleSelectVideoInterview}
        label="Select video interview"
        color="primary"
      />
    </>
  );
};
