import React from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import { OVERALL_STEPS } from 'src/constants';
import { ReactComponent as DescriptionIcon } from 'src/assets/description-icon.svg';

export const OverallStepsSignInPage: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      maxWidth="320px"
      width="100%"
    >
      {OVERALL_STEPS.map((step, idx) => {
        return (
          <Box key={step.label}>
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  borderRadius: '0.5rem',
                  backgroundColor: 'background.success',
                  width: '1.5rem',
                  height: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Typography
                  variant="body1"
                  color="common.white"
                  sx={{
                    fontFamily: 'Arial, sans‑serif',
                    fontSize: '0.85rem',
                    fontWeight: 'bold',
                  }}
                >
                  {idx + 1}
                </Typography>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      display: 'block',
                    },
                  })}
                >
                  <Typography variant="body1" color="text.primary">
                    {step.label}
                    {!!step.time && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        {' '}
                        • {step.time}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!!step.description && (
              <Box display="flex" alignItems="center" mt={1}>
                <Box
                  sx={{
                    borderRadius: '0.5rem',
                    backgroundColor: 'rgba(187, 40, 190, 0.06)',
                    padding: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: 2,
                  }}
                >
                  <SvgIcon
                    inheritViewBox
                    component={DescriptionIcon}
                    sx={() => ({
                      width: '16px',
                      height: '16px',
                    })}
                  />
                </Box>

                <Typography variant="body2" color="text.purple">
                  {step.description}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
