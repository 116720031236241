import { ResponsiveBreak } from 'src/components';
import { EmailForm } from 'src/features/EmailForm/EmailForm';

export const EmailPage: React.FC = () => {
  return (
    <EmailForm
      title={
        <>
          Let's get started with
          <ResponsiveBreak breakpoint={'md'} /> your application right away!
        </>
      }
    />
  );
};
