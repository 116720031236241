import { Box, Typography } from '@mui/material';
import {
  PageTransitionWrapper,
  StepPanel,
  StepPanelHeader,
} from 'src/components';

import welcomeSmile from 'src//assets/welcome-smile.png';
import { aeImageLogoUrl, aeTeamImageUrl } from 'src/constants/apiEndpoints';

export const OfferPage: React.FC = () => {
  return (
    <PageTransitionWrapper>
      <StepPanel data-testid="ti-passed-layout" maxWidth="720px">
        <StepPanelHeader
          srcSetPng={welcomeSmile}
          imageWidth={59}
          alt="WelcomeSmile"
        >
          Your journey with AgileEngine <br />
          goes to the next level – you’re <br /> welcome onboard!
        </StepPanelHeader>
        <Typography variant="body1">
          Your hiring manager will continue with you discussing details in
          emails.
          <br />
          It was a pleasure supporting your job application!
        </Typography>
        <Box
          display="flex"
          gap={5}
          justifyContent="center"
          alignItems="center"
          mt="26px"
        >
          <img
            src={aeImageLogoUrl}
            alt="AgileEngine logo"
            width="92px"
            height="38px"
          />
          <img
            src={aeTeamImageUrl}
            alt="AgileEngine Talent Executives"
            width="182px"
            height="44px"
          />
          <Typography variant="body2" color="text.secondary" textAlign="left">
            Kind regards,
            <br />
            The AgileEngine team
          </Typography>
        </Box>
      </StepPanel>
    </PageTransitionWrapper>
  );
};
