import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'src/redux';
import { format } from 'date-fns';

import { PopupModal } from 'react-calendly';
import { Box, Link, Typography } from '@mui/material';
import { Loader, StepPanel, StepPanelHeader } from 'src/components';
import LetsConnect from 'src/features/letsConnect/LetsConnect';

import { getActiveUserMeetings } from 'src/redux/meeting';

import { useEmojisplosion, useUserMeetings } from 'src/hooks';

import { pollRequest } from 'src/utils';
import { IMeeting, MeetingType } from 'src/types';

import calendarImage from 'src/assets/calendar-image.png';

const ACTIVE_MEETINGS_POLL_INTERVAL = 10;
const ACTIVE_MEETINGS_POLL_ATTEMPTS = 3;

export const TIScheduledLayout = () => {
  const dispatch = useAppDispatch();
  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'CALL_BOOKED');

  const { activeTechnicalInterview, isLoading } = useUserMeetings();

  const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);
  const [isCallBooked, setIsCallBooked] = useState(false);

  const getUserActiveMeetings = useCallback(() => {
    return dispatch(getActiveUserMeetings()).unwrap();
  }, []);

  const pollUserActiveMeetings = () => {
    pollRequest<IMeeting[] | null>({
      fn: getUserActiveMeetings,
      validate: (value) => {
        const ti = value?.find((e) => e.type === MeetingType.TI_CALL);

        return ti?.id !== activeTechnicalInterview?.id;
      },
      interval: ACTIVE_MEETINGS_POLL_INTERVAL * 1000,
      maxAttempts: ACTIVE_MEETINGS_POLL_ATTEMPTS,
    });
  };

  useEffect(() => {
    if (isCallBooked) {
      pollUserActiveMeetings();
    }
  }, [isCallBooked]);

  const handleOpenCalendlyModal = (url: string) => {
    setCalendlyUrl(url);
    setIsCallBooked(false);
  };

  const handleCloseCalendlyModal = () => {
    setCalendlyUrl(null);
    setIsCallBooked(true);
  };

  if (!activeTechnicalInterview) return null;

  return (
    <>
      <StepPanel data-testid="schedule-layout" maxWidth="35.5rem">
        <StepPanelHeader
          ref={starRef}
          srcSetPng={calendarImage}
          imageWidth={{ xs: 57, sm: 77, lg: 90, xxl: 100 }}
          alt="Calendar"
        >
          See you soon!
        </StepPanelHeader>

        {isLoading ? (
          <Box my="1.5rem">
            <Loader position="relative" />
          </Box>
        ) : (
          <>
            <Typography>
              <b>
                The meeting has been scheduled for{' '}
                {format(
                  new Date(activeTechnicalInterview.scheduledDate),
                  "EEEE, MMM d, 'at' hh:mm a",
                )}
              </b>
              <br />
              You will receive a calendar event that you can{' '}
              <Link
                role="button"
                sx={{ cursor: 'pointer' }}
                color="text.brand"
                onClick={() =>
                  handleOpenCalendlyModal(
                    activeTechnicalInterview.reschedule_url,
                  )
                }
              >
                reschedule
              </Link>{' '}
              or{' '}
              <Link
                role="button"
                sx={{ cursor: 'pointer' }}
                color="text.brand"
                onClick={() =>
                  handleOpenCalendlyModal(activeTechnicalInterview.cancel_url)
                }
              >
                cancel
              </Link>
            </Typography>
            <Typography variant="body1">See you!</Typography>
          </>
        )}

        <LetsConnect
          justifyContent={{ xs: 'flex-start', sm: 'center' }}
          mt={2}
        />
      </StepPanel>
      {calendlyUrl && (
        <PopupModal
          url={calendlyUrl}
          open
          onModalClose={handleCloseCalendlyModal}
          rootElement={document.getElementById('root')!}
        />
      )}
    </>
  );
};
