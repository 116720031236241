import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiEndpoints } from '../../constants';
import { userStoreKey } from './user.const';
import {
  cancelApplicationAPI,
  cityPath,
  compensationPath,
  confirmIntermediateScreenPath,
  countryPath,
  crucialRequirementsAPI,
  engagementPath,
  englishLevelPath,
  experiencePath,
  noticePeriodPath,
  officePath,
  selectAnotherTTPath,
  seniorityPath,
  skillsPath,
  submitProfilePath,
} from '../../constants/apiEndpoints';
import { updateUserData } from './user.slice';
import {
  ICompensationBody,
  ICrucialRequirementsBody,
  IEmailBody,
  INamesBody,
  IProfessionalIdentityBody,
  IProfessionalIdentityRequest,
  ISkillsBody,
  IUpdateCityPayload,
  IUpdateOfficePayload,
  IUserData,
} from 'src/types';
import { notificationAlert } from 'src/redux/notifications';
import { formatErrorMessage } from 'src/utils';
import { IExperienceBody } from 'src/features/experience/Experience';
import { setAuthUserEmailSent } from 'src/redux/auth';
import { getAvailableVideoInterview } from 'src/redux/videoInterview';
import { getAvailableTestTasks } from 'src/redux/testTasks';

export const fetchUser = createAsyncThunk(
  `${userStoreKey}/fetchUser`,
  async (disableLoader?: boolean) => { // eslint-disable-line
    const response = await axios.get(apiEndpoints.userProfile());
    return response.data;
  },
);

export const updateEmail = createAsyncThunk(
  `${userStoreKey}/updateEmail`,
  async (body: IEmailBody, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoints.emailPath(), body);

      if (response.data.message) {
        thunkAPI.dispatch(setAuthUserEmailSent(true));
      } else {
        thunkAPI.dispatch(updateUserData(response.data));
      }

      return null;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateName = createAsyncThunk(
  `${userStoreKey}/updateName`,
  async (body: INamesBody, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(
        apiEndpoints.namesPath(),
        body,
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateCountry = createAsyncThunk(
  `${userStoreKey}/updateCountry`,
  async (
    data: {
      country: string;
      notAuthorizedToWorkInUs: boolean | null;
      requireVisaSponsorship: boolean | null;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<IUserData>(countryPath(), data);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateCity = createAsyncThunk(
  `${userStoreKey}/updateCity`,
  async (payload: IUpdateCityPayload, thunkAPI) => {
    try {
      const { city, state, officeLocation } = payload;
      const response = await axios.post<IUserData>(cityPath(), { city, state });

      if (officeLocation) {
        const officeResponse = await axios.post<IUserData>(officePath(), {
          officeLocation,
        });

        return officeResponse.data;
      } else {
        return response.data;
      }
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateOffice = createAsyncThunk(
  `${userStoreKey}/updateOffice`,
  async (payload: IUpdateOfficePayload, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(officePath(), payload);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateEnglishLevel = createAsyncThunk(
  `${userStoreKey}/updateEnglishLevel`,
  async (englishLevel: string, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(englishLevelPath(), {
        englishLevel,
      });

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateEngagement = createAsyncThunk(
  `${userStoreKey}/updateEngagement`,
  async (employmentType: string, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(engagementPath(), {
        employmentType,
      });

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateSkills = createAsyncThunk(
  `${userStoreKey}/updateSkills`,
  async (info: ISkillsBody, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(skillsPath(), info);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateExperience = createAsyncThunk(
  `${userStoreKey}/updateExperience`,
  async (info: IExperienceBody, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(experiencePath(), info);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateSeniority = createAsyncThunk(
  `${userStoreKey}/updateSeniority`,
  async (seniority: string, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(seniorityPath(), {
        seniority,
      });

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateCompensation = createAsyncThunk(
  `${userStoreKey}/updateCompensation`,
  async (info: ICompensationBody, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(compensationPath(), info);

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateNoticePeriod = createAsyncThunk(
  `${userStoreKey}/updateNoticePeriod`,
  async (noticePeriod: number, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(noticePeriodPath(), {
        noticePeriod,
      });

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateProfessionalIdentity = createAsyncThunk(
  `${userStoreKey}/updateProfessionalIdentity`,
  async (info: IProfessionalIdentityBody, thunkAPI) => {
    try {
      const { onUploadProgress } = info;
      delete info.onUploadProgress;

      if (typeof info.originalCvFileName === 'string') {
        delete info.originalCvFileName;
      }

      const form = new FormData();
      Object.entries(info).forEach(([key, value]) => {
        if (value) {
          form.append(key, value);
        }
      });

      const response = await axios.post<IUserData>(
        apiEndpoints.professionalIdentity(),
        form,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress,
        },
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateSubmitProfile = createAsyncThunk(
  `${userStoreKey}/updateSubmitProfile`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(submitProfilePath());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const confirmIntermediateScreen = createAsyncThunk(
  `${userStoreKey}/confirmIntermediateScreen`,
  async () => {
    try {
      const response = await axios.post<IUserData>(
        confirmIntermediateScreenPath(),
      );

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const selectAnotherTT = createAsyncThunk(
  `${userStoreKey}/selectAnotherTT`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(selectAnotherTTPath());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const downloadCv = createAsyncThunk(
  `${userStoreKey}/downloadCv`,
  async (data: IProfessionalIdentityRequest, thunkAPI) => {
    try {
      const response = await axios.get(apiEndpoints.downloadCvPath(), {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return response.data.type;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const cancelApplication = createAsyncThunk(
  `${userStoreKey}/cancelApplication`,
  async (reason: string) => {
    try {
      const response = await axios.post(cancelApplicationAPI(), { reason });

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const confirmCrucialRequirements = createAsyncThunk(
  `${userStoreKey}/confirmCrucialRequirements`,
  async (info: ICrucialRequirementsBody, thunkAPI) => {
    try {
      const response = await axios.post<IUserData>(
        crucialRequirementsAPI(),
        info,
      );

      thunkAPI.dispatch(getAvailableTestTasks());
      thunkAPI.dispatch(getAvailableVideoInterview());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
