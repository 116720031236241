import { useState } from 'react';
import { useAppDispatch } from 'src/redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { CustomButton, Flex } from 'src/components';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { requestViewUserVideoInterviewLink } from 'src/redux/videoInterview';

export const RequestVideoInterviewLink: React.FC<{
  userVideoInterviewId: string | null;
  isExpiredToken: boolean;
}> = ({ userVideoInterviewId, isExpiredToken }) => {
  const dispatch = useAppDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<{ email: string | null }>({
    initialValues: {
      email: null,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Enter a valid email')
        .required('Please enter your email')
        .nullable(),
    }),
    onSubmit: async (values) => {
      if (userVideoInterviewId) {
        setIsLoading(true);
        const res = await dispatch(
          requestViewUserVideoInterviewLink({
            userVideoInterviewId,
            email: values.email!,
          }),
        );
        setIsLoading(false);

        if (requestViewUserVideoInterviewLink.fulfilled.match(res))
          setIsDialogOpen(false);
      }
    },
    validateOnMount: false,
    enableReinitialize: false,
  });

  if (!isExpiredToken || !userVideoInterviewId) return null;

  return (
    <>
      <CustomButton
        label="Request new link"
        onClick={() => setIsDialogOpen(true)}
      />
      <Dialog open={isDialogOpen} scroll="body" fullWidth maxWidth="md">
        <DialogTitle sx={{ padding: '56px 64px 32px 64px' }}>
          <Flex justifyContent="space-between">
            <Typography variant="h2" margin={0}>
              Request new link
            </Typography>
            <CloseIcon
              onClick={() => setIsDialogOpen(false)}
              sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
            />
          </Flex>
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 64px 32px 64px' }}>
          <Typography variant="body1" mb={2}>
            Please provide an email address for us to send the link
          </Typography>
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              formik.touched.email
                ? formik.errors.email || undefined
                : undefined
            }
            disabled={isLoading}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '0px 64px 56px 64px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            gap={3}
            width="100%"
          >
            <CustomButton
              label="Request"
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            />
            <CustomButton
              label="Cancel"
              color="secondary"
              onClick={() => setIsDialogOpen(true)}
              disabled={isLoading}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
