import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { BasicDialog, CustomButton } from 'src/components';
import quizImageSrc from 'src/assets/quiz-image.png';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleRetakeQuestion: () => void;
}

const RetakeConfirmationDialog: React.FC<IProps> = ({
  isOpen = true,
  handleClose,
  handleRetakeQuestion,
}) => {
  return (
    <BasicDialog
      isOpen={isOpen}
      handleClose={handleClose}
      canClickOutside={false}
      overflowVisible
    >
      <Stack gap="2rem" alignItems="center">
        <Box
          component="img"
          srcSet={`${quizImageSrc}`}
          sx={(theme) => ({
            position: 'absolute',
            transform: 'translateX(-55%)',
            left: '50%',
            maxWidth: '150px',
            [theme.breakpoints.up('sm')]: { top: '-5rem', maxWidth: '200px' },
          })}
        />
        <Stack gap="1rem" mt={26}>
          <Typography textAlign="center" variant="h4">
            Want to try again?
          </Typography>
          <Typography textAlign="center" variant="body1">
            Only the last take will be sent.
          </Typography>
        </Stack>
        <Stack direction="row" gap="0.5rem">
          <CustomButton
            label="Record again"
            fullWidth
            onClick={handleRetakeQuestion}
            color="primary"
            style={{ whiteSpace: 'nowrap' }}
          />
          <CustomButton
            label="Return"
            onClick={handleClose}
            color="secondary"
          />
        </Stack>
      </Stack>
    </BasicDialog>
  );
};

export default RetakeConfirmationDialog;
