import React, { useEffect, useMemo, useRef } from 'react';
import { Typography } from '@mui/material';
import {
  Flex,
  ResponsiveBreak,
  StepPanelHeader,
  TextButton,
} from '../../../../../components';
import { useEmojisplosion, useUserTestTasks } from '../../../../../hooks';
import { TestTaskType } from '../../../../../constants';
import startImage from '../../../../../assets/passing-star.png';
import { Box } from '@mui/system';
import LetsConnect from '../../../../letsConnect/LetsConnect';
import { useNavigationType, useNavigate, useLocation } from 'react-router-dom';
import { IUserData, JobOpeningCrucialRequirementCategory } from 'src/types';
import { getUserMissingRequirementsByCategory, isTruthy } from 'src/utils';

const NotSupportedByOpenPositions = ({
  userData,
  isNotSupportedByOpenPositionsSeniority,
  isNotSupportedByOpenPositionLocation,
  isNotSupportedByOpenPositionsCoreStack,
  isNotSupportedByOpenPositionsCrucialRequirements,
}: {
  userData: IUserData | null;
  isNotSupportedByOpenPositionsSeniority: boolean;
  isNotSupportedByOpenPositionLocation: boolean;
  isNotSupportedByOpenPositionsCoreStack: boolean;
  isNotSupportedByOpenPositionsCrucialRequirements: boolean;
}) => {
  const { passedTestTasks } = useUserTestTasks();
  const latestPassedTestTask = passedTestTasks?.[0];

  const isTestTaskPassed = !!latestPassedTestTask;

  const starRef = useRef<HTMLDivElement>(null);
  useEmojisplosion(starRef, 'TT_PASSED');

  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      navigate(1);
      return;
    }
  }, [location]);

  const missingRequirementsMessage = useMemo(() => {
    const missingCrucialRequirements = getUserMissingRequirementsByCategory(
      userData,
      true,
    );
    const missingTechSkills = ([] as Array<string | null>)
      .concat(
        missingCrucialRequirements[
          JobOpeningCrucialRequirementCategory.DomainKnowledge
        ],
      )
      .concat(
        missingCrucialRequirements[
          JobOpeningCrucialRequirementCategory.Technology
        ],
      )
      .filter(isTruthy);
    const missingEducations =
      missingCrucialRequirements[
        JobOpeningCrucialRequirementCategory.Education
      ];
    const missingVisas =
      missingCrucialRequirements[JobOpeningCrucialRequirementCategory.Visa];

    const skillsMessage = missingTechSkills.length ? (
      <>
        experience of{' '}
        {missingTechSkills.map((e, idx) => (
          <>
            <b>{e}</b>
            {idx === missingTechSkills.length - 1 ? '' : ', '}
          </>
        ))}
      </>
    ) : null;
    const educationMessage = missingEducations.length ? (
      <>
        having{' '}
        {missingEducations.map((e, idx) => (
          <>
            <b>{e}</b>
            {idx === missingEducations.length - 1 ? '' : ', '}
          </>
        ))}{' '}
        educational background
      </>
    ) : null;
    const visasMessage = missingVisas.length ? (
      <>
        having{' '}
        {missingVisas.map((e, idx) => (
          <>
            <b>{e}</b>
            {idx === missingVisas.length - 1 ? '' : ', '}
          </>
        ))}{' '}
        visa(s)
      </>
    ) : null;

    return [skillsMessage, educationMessage, visasMessage].filter(isTruthy);
  }, [userData]);

  const handleContactUs = () => {
    const email = 'careers@agileengine.com';
    const subject = 'Question About My Application at AgileEngine.';

    const encodedSubject = encodeURIComponent(subject);

    const mailtoLink = `mailto:${email}?subject=${encodedSubject}`;

    window.location.href = mailtoLink;
  };

  return (
    <>
      {isTestTaskPassed && (
        <>
          <StepPanelHeader
            ref={starRef}
            srcSetPng={startImage}
            alt="Star"
            data-testid="success-title"
          >
            Congratulations! <ResponsiveBreak breakpoint="sm" />
            You've passed!
          </StepPanelHeader>
          {(latestPassedTestTask?.testTaskType === TestTaskType.CODILITY ||
            latestPassedTestTask?.testTaskType === TestTaskType.QUIZ) && (
            <Typography fontWeight="600" data-testid="score-info">
              {latestPassedTestTask.score}% is a good score!
            </Typography>
          )}
          <Typography
            variant="body1"
            mb={0}
            data-testid="not-supported-by-open-positions-text"
          >
            You are our top priority candidate and we will{' '}
            <ResponsiveBreak breakpoint="sm" />
            get back to you with an exciting opportunity{' '}
            <ResponsiveBreak breakpoint="sm" />
            in the nearest future!
          </Typography>
          <LetsConnect justifyContent={{ xs: 'flex-start', sm: 'center' }} />
        </>
      )}
      {!isTestTaskPassed && (
        <>
          <Flex justifyContent="space-between" textAlign="left">
            <Typography variant="h2" mb={0} data-testid="success-title">
              Thank you <ResponsiveBreak breakpoint="xs" />
              for your application!
            </Typography>

            <Box
              component="img"
              src={startImage}
              alt="Star"
              width={{ xs: 57, sm: 67, xxl: 100 }}
              height={{ xs: 57, sm: 67, xxl: 100 }}
            />
          </Flex>
          <Typography
            variant="body1"
            textAlign="left"
            data-testid="not-supported-by-open-positions-text"
          >
            {isNotSupportedByOpenPositionsSeniority && (
              <>
                Our current opportunities don’t match your seniority level.
                <ResponsiveBreak breakpoint="xl" />
                Don’t worry about that! You are our top priority candidate
                as soon as an exciting opportunity comes up!
              </>
            )}
            {isNotSupportedByOpenPositionLocation && (
              <>
                Our current opportunities don’t match your location.
                <ResponsiveBreak breakpoint="xl" />
                Don’t worry about that! You are our top priority candidate
                as soon as an exciting opportunity comes up!
              </>
            )}
            {isNotSupportedByOpenPositionsCoreStack && (
              <>
                Our current opportunities don’t match your skill set.
                <ResponsiveBreak breakpoint="xl" />
                Don’t worry about that! You are our top priority candidate
                as soon as an exciting opportunity comes up!
              </>
            )}
            {isNotSupportedByOpenPositionsCrucialRequirements && (
              <>
                Thank you for taking your time and applying to AgileEngine!
                <br />
                <br />
                Our current opportunities matching your skill set require{' '}
                {missingRequirementsMessage.map((e, idx) => (
                  <>
                    {e}
                    {idx === missingRequirementsMessage.length - 1
                      ? ''
                      : ' and '}
                  </>
                ))}{' '}
                which were not specified in your answers. You are still our top
                priority candidate as soon as a new exciting opportunity comes
                up!
                <br />
                <br />
                Have a great day :-)
              </>
            )}
            <Typography mt={2}>
              <TextButton onClick={handleContactUs}>Contact us</TextButton>
            </Typography>
          </Typography>
          <LetsConnect textAlign="left" mt={2} width="100%" />
        </>
      )}
    </>
  );
};

export default NotSupportedByOpenPositions;
