import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {
  IVideoInterviewQuestion,
  VideoInterviewQuestionStatus,
} from '../../../types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface IProps {
  currentQuestion: IVideoInterviewQuestion;
  questionsAmount: number;
  setQuestionStatus: (questionStatus: VideoInterviewQuestionStatus) => void;
  questionStatus: VideoInterviewQuestionStatus;
}

const VideoRecordInformation: React.FC<IProps> = ({
  currentQuestion,
  questionsAmount,
  setQuestionStatus,
  questionStatus,
}) => {
  const [timeToPrepare, setTimeToPrepare] = useState(
    (currentQuestion.preparation || 1) * 60,
  );
  const [timeToAnswerSec, setTimeToAnswerSec] = useState(
    currentQuestion.duration * 60,
  );

  const [timeYourAnswer, setTimeYourAnswer] = useState(0);

  useEffect(() => {
    setTimeToPrepare((currentQuestion!.preparation || 1) * 60);
    setTimeToAnswerSec(currentQuestion!.duration * 60);
  }, [currentQuestion]);

  useEffect(() => {
    if (questionStatus === VideoInterviewQuestionStatus.LOADING) {
      setTimeToPrepare(3);
    }
  }, [questionStatus]);

  useEffect(() => {
    const interval = setInterval(
      () => setTimeToPrepare(timeToPrepare - 1),
      1000,
    );

    if (timeToPrepare === 3) {
      setQuestionStatus(VideoInterviewQuestionStatus.LOADING);
    }

    if (timeToPrepare === 0) {
      setQuestionStatus(VideoInterviewQuestionStatus.STARTED);
      clearInterval(interval);
    }

    if (questionStatus === VideoInterviewQuestionStatus.STARTED) {
      setTimeToPrepare(0);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeToPrepare]);

  useEffect(() => {
    let interval: any;
    if (
      questionStatus === VideoInterviewQuestionStatus.STARTED ||
      questionStatus === VideoInterviewQuestionStatus.STOPPING
    ) {
      interval = setInterval(
        () => setTimeToAnswerSec(timeToAnswerSec - 1),
        1000,
      );
    }

    if (questionStatus === VideoInterviewQuestionStatus.STOPPING) {
      clearInterval(interval);
      setTimeYourAnswer(currentQuestion!.duration * 60 - timeToAnswerSec);
    }

    if (timeToAnswerSec === 0) {
      clearInterval(interval);
      questionStatus !== VideoInterviewQuestionStatus.STOPPED &&
        setQuestionStatus(VideoInterviewQuestionStatus.STOPPING);
    }

    return () => clearInterval(interval);
  }, [questionStatus, timeToAnswerSec]);

  const minutesParserForAnswer = (timeSec: number) => {
    const minutes = Math.floor(timeSec / 60);
    if (minutes < 10) {
      return `0${minutes}`;
    }

    return minutes;
  };

  const secondsParserForAnswer = (timeSec: number) => {
    const seconds = Math.floor(timeSec % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }

    return seconds;
  };

  return (
    <Box
      sx={(theme) => ({
        padding: '20px 24px',
        display: 'flex',
        flexDirection: 'column',
        ['@media (max-width: 1400px)']: {
          mt: '50px',
          padding: 0,
          width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
          minWidth: '350px',
          maxWidth: '400px',
        },
        [theme.breakpoints.up('xxl')]: {
          padding: '0 55px',
          maxWidth: '550px',
        },
      })}
    >
      <Box sx={{ display: 'flex', mb: '15px' }}>
        {new Array(questionsAmount).fill(null).map((_, index) => (
          <Box
            sx={(theme) => ({
              width: '10px',
              height: '10px',
              background:
                index + 1 === currentQuestion!.questionNumber
                  ? theme.palette.brand.secondary
                  : index + 1 < currentQuestion!.questionNumber
                  ? theme.palette.success.main
                  : '#FFB74D',
              borderRadius: '50%',
              marginRight: '8px',
            })}
            key={index}
          />
        ))}
      </Box>
      <Typography sx={{ color: 'black' }} variant="h3">
        {currentQuestion!.title}
      </Typography>
      <Box my={2} sx={{ display: 'flex' }}>
        {(questionStatus === VideoInterviewQuestionStatus.PREPARATION ||
          questionStatus === VideoInterviewQuestionStatus.LOADING) && (
          <Box mr={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={(theme) => ({
                color: theme.palette.brand.secondary,
                fontSize: '13px',
              })}
            >
              Time to prepare
            </Typography>
            <Typography sx={{ color: 'black' }} variant="h3">
              {`${minutesParserForAnswer(
                timeToPrepare,
              )} : ${secondsParserForAnswer(timeToPrepare)}`}
            </Typography>
          </Box>
        )}
        {questionStatus !== VideoInterviewQuestionStatus.STOPPED && (
          <Box mr={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                color:
                  questionStatus === VideoInterviewQuestionStatus.STARTED ||
                  questionStatus === VideoInterviewQuestionStatus.STOPPING
                    ? timeToAnswerSec < 10
                      ? 'red'
                      : 'brand.accent'
                    : 'black',
                fontSize: '13px',
              }}
            >
              Time to answer
            </Typography>
            <Typography
              sx={{ color: timeToAnswerSec < 10 ? 'red' : 'black' }}
              variant="h3"
            >
              {questionStatus === VideoInterviewQuestionStatus.STARTED ||
              questionStatus === VideoInterviewQuestionStatus.STOPPING
                ? `${minutesParserForAnswer(
                    timeToAnswerSec,
                  )} : ${secondsParserForAnswer(timeToAnswerSec)}`
                : `${timeToAnswerSec / 60} min`}
            </Typography>
          </Box>
        )}
        {questionStatus === VideoInterviewQuestionStatus.STOPPED && (
          <Box mr={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="text.secondary" sx={{ fontSize: '13px' }}>
              Your answer
            </Typography>
            <Typography sx={{ color: 'black' }} variant="h3">
              {`${minutesParserForAnswer(
                timeYourAnswer,
              )} : ${secondsParserForAnswer(timeYourAnswer)}`}
            </Typography>
          </Box>
        )}
      </Box>
      {questionStatus !== VideoInterviewQuestionStatus.STOPPED && (
        <Typography
          color="text.secondary"
          sx={{ display: 'flex', fontSize: '13px', alignItems: 'center' }}
          mt={2}
        >
          {questionStatus === VideoInterviewQuestionStatus.STARTED ||
          questionStatus === VideoInterviewQuestionStatus.STOPPING ? (
            <FiberManualRecordIcon
              sx={{ mr: 1, fontSize: '16px', color: 'red' }}
            />
          ) : (
            <AccessTimeIcon sx={{ mr: 1, fontSize: '16px' }} />
          )}
          {questionStatus === VideoInterviewQuestionStatus.PREPARATION &&
            'Not recording'}
          {questionStatus === VideoInterviewQuestionStatus.LOADING &&
            'Starting recording'}
          {(questionStatus === VideoInterviewQuestionStatus.STARTED ||
            questionStatus === VideoInterviewQuestionStatus.STOPPING) &&
            'Recording'}
        </Typography>
      )}
    </Box>
  );
};

export default VideoRecordInformation;
