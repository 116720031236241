import { useEffect } from 'react';
import { useAppSelector } from 'src/redux';

import { userSelectors } from 'src/redux/user';

import { setUser } from 'src/services';

export const useAnalytics = () => {
  const userData = useAppSelector(userSelectors.getUserData);

  useEffect(() => {
    if (userData?.id) {
      setUser(userData?.id);
    }
  }, [userData?.id]);
};
