import { Box, SvgIcon, Typography } from '@mui/material';
import { PageTransitionWrapper } from 'src/components';

import { ReactComponent as DescriptionIcon } from 'src/assets/description-icon.svg';

export const ProfessionalBenefits: React.FC<{
  data: string[] | null;
}> = ({ data }) => {
  if (!data) return null;

  return (
    <PageTransitionWrapper
      display="flex"
      flexDirection="column"
      gap={3}
      width="100%"
      alignItems="center"
    >
      <Typography variant="h1" textAlign="center">
        Personal and professional development
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} maxWidth="407px">
        {data.map((benefit) => (
          <Box key={benefit} width="100%">
            <Box display="flex" gap={1}>
              <SvgIcon
                inheritViewBox
                component={DescriptionIcon}
                sx={() => ({
                  width: '12px',
                  height: '12px',
                  mt: '6px',
                })}
              />
              <Typography
                dangerouslySetInnerHTML={{ __html: benefit }}
              ></Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </PageTransitionWrapper>
  );
};
