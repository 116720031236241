import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import TestTasksSelectableList from '../components/TestTasksSelectableList';
import PassTestTaskButton from '../components/PassTestTaskButton';
import RescheduleTestTaskButton from '../components/RescheduleTestTaskButton';
import LabeledTextButton from 'src/components/labledTextButton/LabeledTextButton';

import { AvailableTestTaskWithPositionsData } from '../../../types';

import { TTScheduledPanel } from '../components/TTScheduledPanel';
import { Flex } from '../../../components';
import { CodilityDescription } from '../components/CodilityDescription';
import { useFeatureFlags } from '../../../hooks';
import {
  SELECT_TECHNOLOGY_ERROR_MESSAGE,
  TEST_TASK_STATUS,
} from 'src/constants/testTask';

interface IMultipleTTLayoutProps {
  testTaskSelected: string | null;
  testTasks?: AvailableTestTaskWithPositionsData[];
  isPerformingAction: boolean;
  testTaskReminderDate: string | null;
  handleTTChange: (testTask: AvailableTestTaskWithPositionsData) => void;
  handlePassTTClick: () => void;
  handleScheduleForLater: () => void;
}

const MultipleTTLayout: React.FC<IMultipleTTLayoutProps> = ({
  testTaskSelected = '',
  testTasks,
  isPerformingAction,
  testTaskReminderDate,
  handleTTChange,
  handlePassTTClick,
  handleScheduleForLater,
}) => {
  const { isTTSelectionPageSimplified } = useFeatureFlags();

  const [showSelectTTError, setShowSelectTTError] = useState<boolean>(false);
  const hasFailedTestTasks = testTasks?.some(
    (tt) => tt.status === TEST_TASK_STATUS.FAILED,
  );

  useEffect(() => {
    if (showSelectTTError && testTaskSelected) {
      setShowSelectTTError(false);
    }
  }, [showSelectTTError, testTaskSelected]);

  const onProceedTTClick = () => {
    if (!testTaskSelected) {
      setShowSelectTTError(true);
      return;
    }

    handlePassTTClick();
  };

  return (
    <>
      {!isTTSelectionPageSimplified && (
        <Typography
          variant="body1"
          mb={1}
          data-testid="tt-not-passed-yet-subtext"
        >
          Please select a language you prefer:
        </Typography>
      )}
      <TestTasksSelectableList
        selectedTestTask={testTaskSelected}
        testTasks={testTasks}
        handleChange={handleTTChange}
      />
      {hasFailedTestTasks && (
        <Typography
          variant="body2"
          color="secondary"
          mb={2}
          data-testid="failed-tt-notifier"
        >
          You have done it before with a low score,
          <br />
          you can proceed with other technologies.
        </Typography>
      )}
      <Stack spacing={3} direction="column" mt={4}>
        {testTaskReminderDate ? (
          <>
            <TTScheduledPanel
              testTaskReminderDate={testTaskReminderDate}
              handleScheduleForLater={handleScheduleForLater}
            />
            <Flex
              gap={4}
              flexWrap="wrap"
              alignItems="flex-start"
              flexDirection="column"
            >
              <LabeledTextButton
                label="In case you’re still up to it now"
                buttonText="Proceed to the test"
                onClick={onProceedTTClick}
                errorMessage={
                  showSelectTTError && SELECT_TECHNOLOGY_ERROR_MESSAGE
                }
              />
              <CodilityDescription />
            </Flex>
          </>
        ) : (
          <Flex flexDirection="column" gap={4} alignItems="start">
            <Flex gap={2}>
              <PassTestTaskButton
                isDisabled={!testTaskSelected}
                isLoading={isPerformingAction}
                handlePassTTClick={handlePassTTClick}
              />
              <RescheduleTestTaskButton
                isDisabled={isPerformingAction}
                testTaskReminderDate={testTaskReminderDate}
                handleScheduleForLater={handleScheduleForLater}
              />
            </Flex>
            {!isTTSelectionPageSimplified && <CodilityDescription />}
          </Flex>
        )}
      </Stack>
    </>
  );
};

export default MultipleTTLayout;
