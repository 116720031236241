import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import VideoJSComponent from './components/VideoJSComponent';
import RecordListOfDescription from './components/RecordListOfDescriptions';
import VideoRecordInformation from './components/VideoRecordInformation';
import { useAppSelector } from '../../redux';
import { userSelectors } from '../../redux/user';
import { ScheduleDialog } from '../../components';
import SelectionButtons from './components/SelectionButtons';
import RecordActionsButtons from './components/RecordActionsButtons';
import { ExitInterviewDialog } from './components/ExitInterviewDialog';
import RecordInputsButtons from './components/RecordInputsButtons';
import FinishInterviewLayout from './components/FinishInterviewLayout';
import { SelectChangeEvent } from '@mui/material/Select';
import { useIsIndependentVIPage, useUserVideoInterview } from '../../hooks';
import { LocalStorageService, logEvent } from '../../services';
import { LOCAL_STORAGE_KEYS, WORK_AREA_TYPE } from '../../constants';
import { useAppDispatch } from '../../redux';
import {
  finishVideoInterviewQuestion,
  finishVideoInterview,
} from '../../redux/videoInterview';
import {
  IVideoInterviewQuestion,
  VideoInterviewQuestionStatus,
} from '../../types/videoInterviewQuestion';
import { UserVideoInterviewStatus } from 'src/types/userVideoInterviewData';
import ShareFeedbackDialog from 'src/features/shareFeedbackDialog/ShareFeedbackDialog';
import RetakeConfirmationDialog from './components/RetakeConfirmationDialog';

const RecordVideo: React.FC = () => {
  const dispatch = useAppDispatch();
  const playerRef = useRef(null);

  const {
    specializationType,
    isLoading,
    userVideoInterview,
    allQuestionsAmount,
    finishedQuestionsAmount,
    currentQuestionNumber,
    isLastQuestion,
  } = useUserVideoInterview();
  const userData = useAppSelector(userSelectors.getUserData)!;
  const isIndependentVIPage = useIsIndependentVIPage();
  const userReminderDate = userData?.videoInterviewReminderDate;

  const [deviceIsBlocked, setDeviceIsBlocked] = useState(false);
  const [deviceIsReady, setDeviceIsReady] = useState(false);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [showRetakeDialog, setShowRetakeDialog] = useState(false);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [currentAudioInput, setCurrentAudioInput] = useState('');
  const [currentVideoInput, setCurrentVideoInput] = useState('');
  const [showRescheduleMessage, setShowRescheduleMessage] = useState(
    Boolean(userReminderDate),
  );
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showExitInterviewDialog, setShowExitInterviewDialog] = useState(false);
  const [isStartRecord, setIsStartRecord] = useState(false);
  const [currentQuestion, setCurrentQuestion] =
    useState<IVideoInterviewQuestion | null>(
      userVideoInterview?.videoInterview?.questions.find(
        (vi) => vi.questionNumber === currentQuestionNumber + 1,
      ) || null,
    );
  const [questionStatus, setQuestionStatus] =
    useState<VideoInterviewQuestionStatus>(
      VideoInterviewQuestionStatus.PREPARATION,
    );
  const [recordData, setRecordData] = useState(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const isEngineeringSpecialization = useMemo(
    () => specializationType === WORK_AREA_TYPE.ENGINEERING,
    [specializationType],
  );

  const nonTechInformBar =
    specializationType === WORK_AREA_TYPE.NON_ENGINEERING &&
    deviceIsReady &&
    !isStartRecord;

  const finishRecordQuestion = () => {
    const player = playerRef.current as any;
    if (player.player_) {
      player.record().stop();
    }
  };

  const startRecordQuestion = () => {
    const player = playerRef.current as any;
    player.record().start();
  };

  const resetRecordQuestion = () => {
    const player = playerRef.current as any;
    player.record().reset();
  };

  const destroyRecordQuestion = () => {
    const player = playerRef.current as any;
    player.record().destroy();
  };

  const handleFinishVideoInterview = () => {
    if (!userVideoInterview) return;

    finishRecordQuestion();
    destroyRecordQuestion();

    dispatch(
      finishVideoInterview({
        userVideoInterviewId: userVideoInterview.id,
      }),
    );

    LocalStorageService.clearState(
      LOCAL_STORAGE_KEYS.START_VIDEO_INTERVIEW_RECORD,
    );
  };

  useEffect(() => {
    logEvent('launchpod - video-interview-record-page_loaded');

    if (userVideoInterview && finishedQuestionsAmount === allQuestionsAmount) {
      setQuestionStatus(VideoInterviewQuestionStatus.STOPPED);
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      e.preventDefault();
      if (
        questionStatus === VideoInterviewQuestionStatus.STARTED ||
        questionStatus === VideoInterviewQuestionStatus.LOADING ||
        questionStatus === VideoInterviewQuestionStatus.STOPPING
      ) {
        return true;
      }
    };

    window.onunload = () => {
      finishRecordQuestion();
    };

    if (
      LocalStorageService.getState(
        LOCAL_STORAGE_KEYS.START_VIDEO_INTERVIEW_RECORD,
      )
    ) {
      setIsStartRecord(true);
    }
  }, [questionStatus]);

  useEffect(() => {
    if (
      questionStatus === VideoInterviewQuestionStatus.STARTED &&
      userVideoInterview &&
      currentQuestion
    ) {
      startRecordQuestion();
    } else if (questionStatus === VideoInterviewQuestionStatus.STOPPING) {
      finishRecordQuestion();
    }
  }, [questionStatus]);

  useEffect(() => {
    const handleQuestionAnswer = async () => {
      if (recordData) {
        await dispatch(
          finishVideoInterviewQuestion({
            userVideoInterviewId: userVideoInterview!.id,
            questionNumber: currentQuestion!.questionNumber,
            record: recordData,
            onUploadProgress,
          }),
        );
      }
    };

    handleQuestionAnswer();
  }, [recordData]);

  useEffect(() => {
    if (
      uploadProgress === 100 &&
      questionStatus === VideoInterviewQuestionStatus.STOPPING
    ) {
      setUploadProgress(0);
      setQuestionStatus(VideoInterviewQuestionStatus.STOPPED);
    }

    const nextQuestion = userVideoInterview?.videoInterview?.questions.find(
      (vi) => vi.questionNumber === currentQuestionNumber + 1,
    );

    if (
      questionStatus === VideoInterviewQuestionStatus.FINISHED &&
      !isLoading
    ) {
      setQuestionStatus(VideoInterviewQuestionStatus.PREPARATION);
      setCurrentQuestion(nextQuestion || null);
      setRecordData(null);
      resetRecordQuestion();
    }
  }, [questionStatus, isLoading, uploadProgress, userVideoInterview]);

  const handleScheduleSuccess = () => {
    setShowRescheduleMessage(true);
    setShowScheduleDialog(false);
  };

  const handleSelectAudioInput = (event: SelectChangeEvent) => {
    setCurrentAudioInput(event.target.value as string);
    if (playerRef && playerRef.current) {
      (playerRef.current as any).record().setAudioInput(event.target.value);
    }
  };

  const handleSelectVideoInput = (event: SelectChangeEvent) => {
    setCurrentVideoInput(event.target.value as string);

    if (playerRef && playerRef.current) {
      (playerRef.current as any).record().setVideoInput(event.target.value);
    }
  };

  const handleStartRecordSuccess = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    logEvent('launchpod - video-interview-record-page_start-interview');

    LocalStorageService.setState(
      LOCAL_STORAGE_KEYS.START_VIDEO_INTERVIEW_RECORD,
      true,
    );
    setIsStartRecord(true);
  };

  const handleBrowserSettings = async (player: any) => {
    const permissionName = 'camera' as PermissionName;
    const settings = await navigator.permissions.query({
      name: permissionName,
    });
    settings.addEventListener(
      'change',
      () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {
            player.record().getDevice();
            setDeviceIsBlocked(false);
            setDeviceIsReady(true);
          })
          .catch(() => {
            setDeviceIsReady(false);
            setDeviceIsBlocked(true);
          });
      },
      { once: false },
    );
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.on('ready', function () {
      handleBrowserSettings(player);
      player.record().getDevice();
    });

    player.on('deviceReady', () => {
      setDeviceIsReady(true);
      player.record().enumerateDevices();
    });

    player.on('enumerateReady', () => {
      const devices = player.record().devices;
      const audioInputs = devices.filter(
        ({ kind }: { kind: string }) => kind === 'audioinput',
      );
      const videoInputs = devices.filter(
        ({ kind }: { kind: string }) => kind === 'videoinput',
      );
      setAudioInputDevices(audioInputs);
      setVideoInputDevices(videoInputs);
      audioInputs.length && setCurrentAudioInput(audioInputs[0].deviceId);
      videoInputs.length && setCurrentVideoInput(videoInputs[0].deviceId);
    });

    player.on('finishRecord', () => {
      setRecordData(player.recordedData);
      player.controls(true);
    });

    player.on('deviceError', () => {
      if (player.deviceErrorCode.message === 'Permission denied') {
        setDeviceIsBlocked(true);
      }
    });
  };

  const handleRetakeQuestion = () => {
    let question = userVideoInterview?.videoInterview?.questions.find(
      (vi) => vi.questionNumber === currentQuestionNumber,
    );

    if (question && question.preparation) {
      question = JSON.parse(JSON.stringify(question));
      question!.preparation = 0.2;
    }

    setShowRetakeDialog(false);
    setUploadProgress(0);
    setQuestionStatus(VideoInterviewQuestionStatus.LOADING);
    setCurrentQuestion(question || null);
    setRecordData(null);
    resetRecordQuestion();
  };

  const onUploadProgress = (percents: number) => {
    setUploadProgress(percents);
  };

  const isIndependentVideoInterviewFinished = useMemo(() => {
    return (
      userVideoInterview?.status === UserVideoInterviewStatus.FINISHED &&
      isIndependentVIPage
    );
  }, [userVideoInterview, isIndependentVIPage]);

  if (isIndependentVideoInterviewFinished) {
    return <FinishInterviewLayout />;
  }

  return (
    <>
      {showScheduleDialog && (
        <ScheduleDialog
          isOpen={showScheduleDialog}
          handleClose={() => setShowScheduleDialog(false)}
          handleScheduleSuccess={handleScheduleSuccess}
          isVideoInterview={true}
        />
      )}
      {showExitInterviewDialog && (
        <ExitInterviewDialog
          isOpen={showExitInterviewDialog}
          handleClose={() => setShowExitInterviewDialog(false)}
          handleFinishVideoInterview={handleFinishVideoInterview}
        />
      )}
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        })}
      >
        <Typography
          variant="h2"
          mb={3}
          sx={(theme) => ({
            color: isStartRecord ? 'text.secondary' : 'text.primary',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        >
          {isStartRecord
            ? 'Tell us about yourself'
            : 'Prepare your camera and mic'}
        </Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            width: '511px',
            [theme.breakpoints.down('xl')]: {
              flexDirection: 'column',
              alignItems: isStartRecord ? 'inherit' : 'center',
            },
            ['@media (max-width: 1100px)']: {
              width: '100%',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.down('xl')]: {
                width: '100%',
              },
            })}
          >
            <VideoJSComponent
              onReady={handlePlayerReady}
              deviceIsReady={deviceIsReady}
              deviceIsBlocked={deviceIsBlocked}
              duration={currentQuestion?.duration || 0}
              questionStatus={questionStatus}
            />
          </Box>
          {!isStartRecord && (
            <Typography
              variant="h2"
              mb={3}
              sx={(theme) => ({
                display: 'none',
                [theme.breakpoints.down('md')]: {
                  display: 'flex',
                  mt: '20px',
                },
              })}
            >
              Ready?
            </Typography>
          )}
          {nonTechInformBar && (
            <Box
              sx={(theme) => ({
                background: '#F4FBFE',
                height: '281px',
                borderRadius: '8px',
                padding: '0 55px',
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('xl')]: {
                  display: 'none',
                },
                [theme.breakpoints.up('xl')]: {
                  minWidth: '350px',
                  justifyContent: 'center',
                  maxWidth: '400px',
                },
                [theme.breakpoints.up('xxl')]: {
                  padding: '0 55px',
                  maxWidth: '550px',
                },
              })}
            >
              <RecordListOfDescription />
            </Box>
          )}
          {isStartRecord && currentQuestion && (
            <VideoRecordInformation
              currentQuestion={currentQuestion}
              questionsAmount={allQuestionsAmount}
              setQuestionStatus={setQuestionStatus}
              questionStatus={questionStatus}
            />
          )}
        </Box>
        {!isStartRecord && (
          <RecordInputsButtons
            videoInputDevices={videoInputDevices}
            handleSelectVideoInput={handleSelectVideoInput}
            currentVideoInput={currentVideoInput}
            audioInputDevices={audioInputDevices}
            handleSelectAudioInput={handleSelectAudioInput}
            currentAudioInput={currentAudioInput}
            deviceIsBlocked={deviceIsBlocked}
          />
        )}
        {nonTechInformBar && (
          <Box
            sx={(theme) => ({
              display: 'none',
              marginTop: '20px',
              [theme.breakpoints.down('xl')]: {
                display: 'flex',
              },
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          >
            <RecordListOfDescription />
          </Box>
        )}
        {isStartRecord ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <RecordActionsButtons
              questionStatus={questionStatus}
              setQuestionStatus={setQuestionStatus}
              setShowExitInterviewDialog={setShowExitInterviewDialog}
              isLastQuestion={isLastQuestion}
              handleFinishVideoInterview={handleFinishVideoInterview}
              showReportIssueDialog={() => setOpenReportIssue(true)}
              handleShowRetakeDialog={() => setShowRetakeDialog(true)}
              uploadProgress={uploadProgress}
              isEngineeringSpecialization={isEngineeringSpecialization}
            />
          </Box>
        ) : (
          <SelectionButtons
            deviceIsReady={deviceIsReady}
            setShowScheduleDialog={setShowScheduleDialog}
            showRescheduleMessage={showRescheduleMessage}
            setShowRescheduleMessage={setShowRescheduleMessage}
            handleStartRecordSuccess={handleStartRecordSuccess}
            questionNumber={currentQuestion?.questionNumber || 0 + 1}
          />
        )}
        {nonTechInformBar && (
          <Box
            sx={(theme) => ({
              display: 'none',
              marginTop: '20px',
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                maxWidth: '300px',
                margin: '40px auto',
              },
            })}
          >
            <RecordListOfDescription />
          </Box>
        )}
      </Box>
      <ShareFeedbackDialog
        isOpen={openReportIssue}
        handleClose={() => setOpenReportIssue(false)}
      />
      <RetakeConfirmationDialog
        isOpen={showRetakeDialog}
        handleClose={() => setShowRetakeDialog(false)}
        handleRetakeQuestion={handleRetakeQuestion}
      />
    </>
  );
};

export default RecordVideo;
