export const APP_ROUTES = {
  HOME: '/',
  EMAIL: 'email',
  NAME: 'name',
  PROFESSIONAL_IDENTITY: 'professional-identity',
  COUNTRY: 'country',
  CITY: 'city',
  OFFICE: 'office',
  ENGLISH_LEVEL: 'english-level',
  TECH_SKILLS: 'tech-skills',
  SPECIALIZATION: 'specialization',
  PRIMARY_SKILLS: 'primary-skills',
  SECONDARY_SKILLS: 'secondary-skills',
  EXPERIENCE: 'experience',
  SENIORITY: 'seniority',
  ENGAGEMENT: 'engagement',
  COMPENSATION: 'compensation',
  NOTICE_PERIOD: 'notice-period',
  PROFILE_REVIEW: 'profile-review',
  ABOUT_YOURSELF: 'about-yourself',
  VIDEO_INTERVIEW_SELECTION: 'video-interview-selection',
  VIDEO_INTERVIEW_FINISHED: 'video-interview-finished',
  RECORD_VIDEO: 'record-video',
  TEST_TASK_SELECTION: 'test-task-selection',
  CODILITY_TEST_TASK: 'codility-test-task',
  QUIZ_TEST_TASK: 'quiz-test-task',
  INTERNAL_TEST_TASK: 'internal-test-task',
  TEST_TASK_FINISHED_FINISHED: 'test-task-finished',
  BENEFITS: 'benefits',
  APPLICATION_SUCCESS: 'application-success',
  UNSUBSCRIBE_FROM_REMINDERS: 'user-settings/unsubscribe',
  RESOLVE_USER_DUPLICATION: 'resolve-user-duplication',
  REVIEW_TEST_TASK: 'admin/review-test-task',
  INTERNAL_TEST_TASK_SENT: 'internal-test-task-sent',
  TERMS_OF_USE: 'terms-of-use',
  REFER_FRIEND: 'refer-a-friend',
  DECLINE_REFERRAL: 'refer-a-friend/decline',
  UNSUBSCRIBE_REFERRAL: 'refer-a-friend/unsubscribe',
  LONG_TIME_NO_SEE: 'long-time-no-see',
  TECHNICAL_INTERVIEW: 'technical-interview',
  KNOWLEDGE_QUIZ: 'knowledge-quiz',
  INTRO_CALL: 'intro-call',
  SUCCESSFUL_POSITION_APPLICATION: 'successful-position-application',
  CANDIDATE_VIDEO_INTERVIEW_START: '/video-interview/start',
  CANDIDATE_VIDEO_INTERVIEW_RECORD: '/video-interview/record',
  VIDEO_INTERVIEW_VIEW: '/video-interview/view',
  CANCEL_APPLICATION: 'cancel-application',
  FIRST_STEP_IS_DONE: 'first-step-is-done',
  SKILLS_REQUIREMENTS: 'requirements/skills',
  EDUCATION_REQUIREMENTS: 'requirements/education',
  VISA_REQUIREMENTS: 'requirements/visa',
  OFFER: 'offer',
};

export const STANDALONE_PAGES = [
  APP_ROUTES.REFER_FRIEND,
  APP_ROUTES.DECLINE_REFERRAL,
  APP_ROUTES.UNSUBSCRIBE_REFERRAL,
  APP_ROUTES.UNSUBSCRIBE_FROM_REMINDERS,
  APP_ROUTES.TERMS_OF_USE,
  APP_ROUTES.SUCCESSFUL_POSITION_APPLICATION,
  APP_ROUTES.VIDEO_INTERVIEW_VIEW,
];
