import React from 'react';
import { CustomButton } from '../../../components';
import { Box, SvgIcon } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import { VideoInterviewQuestionStatus } from 'src/types';
import { ProgressButton } from 'src/components/progressButton/ProgressButton';

interface IProps {
  questionStatus: VideoInterviewQuestionStatus;
  handleStopQuestion: () => void;
  uploadProgress: number;
}

const StopRecordingButton: React.FC<IProps> = ({
  questionStatus,
  handleStopQuestion,
  uploadProgress,
}) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: 260,
          [theme.breakpoints.down('xl')]: {
            width: 511,
          },
          position: 'relative',
          ['@media (max-width: 1200px)']: {
            width: '100%',
          },
          [theme.breakpoints.down('md')]: {
            width: 400,
          },
          ['@media (max-width: 400px)']: {
            width: '100%',
          },
        })}
      >
        {questionStatus === VideoInterviewQuestionStatus.STOPPING ? (
          <ProgressButton
            label="Uploading your record"
            fullWidth
            color="primary"
            progress={uploadProgress}
            isLoading={true}
          />
        ) : (
          <CustomButton
            label={
              questionStatus === VideoInterviewQuestionStatus.STARTED
                ? 'Finish recording'
                : 'Uploading record'
            }
            startIcon={<SvgIcon inheritViewBox component={StopIcon} />}
            onClick={handleStopQuestion}
            fullWidth
            color="primary"
          />
        )}
      </Box>
    </>
  );
};

export default StopRecordingButton;
