import { Box, Typography } from '@mui/material';
import { PageTransitionWrapper, ResponsiveBreak } from 'src/components';

import { hiFiveImgURL } from 'src/constants/apiEndpoints/awsS3';

export const ReferralBenefits: React.FC<{
  data: string[] | null;
}> = ({ data }) => {
  if (!data) return null;

  return (
    <PageTransitionWrapper
      display="flex"
      flexDirection="column"
      gap={3}
      width="100%"
    >
      <Typography variant="h1" textAlign="center">
        Get nice cash bonuses
        <ResponsiveBreak breakpoint="sm" /> for bringing your friends
        to AgileEngine
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img src={hiFiveImgURL} alt="hi five" width="147px" height="147px" />
        <Box component="ul">
          {data.map((benefit) => (
            <Box key={benefit} component="li">
              <Typography
                dangerouslySetInnerHTML={{ __html: benefit }}
              ></Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </PageTransitionWrapper>
  );
};
